<template>
  <div class="WishList">
    <Header/>
    <div class="wis-top-space-line"></div>
    <div class="wis-content">
      <div class="titles">
        <div class="titleBig">{{'愿望清单' | formatTrans}}</div>
      </div>
      <div class="line">
        <img src="@/assets/images/login/login.png" style="width: 100%; ">
      </div>
      <div class="clearb"></div>
      <div class="box">
        <div class="flexT">
          <div class="findproducts">
            <div
              class="hasDatas"
              v-show="this.favoritesGoodsLists.length <= 0"
            >{{"我们未找到任何东西" | formatTrans}}</div>

            <div
              class="findproductsImg"
              v-for="(ele,index) in favoritesGoodsLists"
              :key="index"
              @mouseenter="hoverIndex = index"
              @mouseleave="hoverIndex = null"
            >
              <div class="hoverBg" v-show="hoverIndex === index">
                <div>
                  <a @click="goProductsDesc(ele.goodsId)">{{'立即购买' | formatTrans}}</a>
                </div>
                <div>
                  <a @click="RemoveFn(ele.commonId)">{{'删除' | formatTrans}}</a>
                </div>
              </div>
              <img class="commonImage" :src="ele.commonImage" alt="image">
              <div class="findproductsDesc">
                <div class="name">{{ele.commonName}}</div>
              </div>
              <div class="price">
                <div>{{ curSymbol }}{{ ele.commonPrice }}</div>
                <div @click="goProductsDesc(ele.goodsId)">
                  <img :src="shoppingBgImg" alt="image">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearb"></div>
    </div>
    <div class="wis-bot-space-line"></div>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import shoppingBgImg from "@/assets/images/shoppingBg.png";
import { favoritesGoods, deletefavoritesGoods, logout } from "@/api/index";
import { getTrans } from "@/api/set.js";

export default {
  name: "Home",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      hoverIndex: "",
      showhoverBg: false,
      shoppingBgImg,
      favoritesGoodsLists: [],
      rules: {},
      curSymbol: ""
    };
  },

  mounted() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
    this.getFavoritesGoods();
  },

  activated() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
  },

  methods: {
    // 获取心愿清单
    getFavoritesGoods() {
      let data = {
        limit: 10,
        page: 1,
        token: sessionStorage.getItem("token")
      };
      favoritesGoods(data).then(res => {
        if (res.code == 1 && res.data) {
          this.favoritesGoodsLists = res.data.list;
        } else if (res.code == 10016) {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.$message({
            message: res.message,
            type: "error"
          });
        }
      });
    },
    enter() {
      this.showhoverBg = true;
      // this.current = index;
    },
    leave() {
      this.showhoverBg = false;
      // this.current = null;
    },

    RemoveFn(id) {
      let data = {
        commonIds: [id.toString()],
        token: sessionStorage.getItem("token")
      };

      deletefavoritesGoods(data).then(response => {
        if (response.code == 1) {
          setTimeout(this.getFavoritesGoods(), 3000);
          // this.$message({
          //   message: "success",
          //   type: "success",
          //   customClass: "messageIndex",
          //   duration: 3000
          // });
        } else if (response.code == 10016) {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.$message({
            message: response.message,
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });
        }
      });
      setTimeout(this.getFavoritesGoods(), 2000);
    },

    // 跳转商品详情
    goProductsDesc(id) {
      this.$router.push({
        path: "/productsDesc",
        query: {
          goodsId: id
        }
      });
    },
    shopnow(val) {
      this.val = val;
    },
    goshopping(val) {
      this.val = val;
    },
    Remove(val) {
      this.val = val;
    }
  }
};
</script>
<style lang="scss" scoped>
.WishList {
  .wis-top-space-line {
    height: 80px;
  }

  .wis-bot-space-line {
    height: 100px;
  }

  .wis-content {
    width: 1200px;
    margin: 0 auto;
    margin-top: 48px;
  }

  .titles {
    text-align: center;
    .titleBig {
      font-size: 40px;
      color: #333;
      font-weight: 900;
    }
    .titleSmall {
      font-size: 14px;
      color: #666;
      margin: 10px 0;
    }
  }

  .line {
    width: 972px;
    margin: 50px auto;

    img {
      width: 100%;
    }
  }

  .box {
    margin: 20px 0;
    padding-bottom: 50px;
    font-size: 16px !important;

    ::v-deep .el-form-item__content,
    ::v-deep .el-checkbox__label {
      font-size: 18px !important;
    }

    .findproducts {
      margin: 0 auto;
      width: 100%;
      text-align: center;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      margin-top: 0px;

      .hasDatas {
        margin-top: 50px;
      }

      .commonImage {
        width: 256px;
        // height: 200px;
        margin: 40px 10px 10px 10px;
      }

      .findproductsImg:first-child {
        margin-left: 0 !important;
      }

      .findproductsImg:hover {
        background: #f0f0f0;
        box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.35);
      }

      .findproductsImg {
        cursor: pointer;
        border-radius: 48px;
        background-color: rgba(243, 243, 245, 1);
        margin: 20px 24px 20px 0;
        position: relative;
        width: 276px;
        height: 364px;
        float: left;
        .price {
          color: rgba(127, 73, 42, 1);
          font-size: 20px;
          width: 100%;
          position: absolute;
          bottom: 10px;
          div:first-child {
            text-align: left;
            float: left;
            margin-left: 25px;
            line-height: 35px;
          }

          div:last-child {
            float: right;
            padding-bottom: 10px;
            padding-right: 15px;
          }

          img {
            width: 50%;
            margin: 0;
            cursor: pointer;
          }
        }

        .findproductsDesc {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;

          .name {
            color: rgba(0, 0, 0, 0.85);
            font-size: 24px;
            padding-top: 20px;
            padding-left: 25px;
            width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
          }
        }
      }
    }

    .hoverBg {
      background-color: rgba(0, 0, 0, 0.55);
      padding: 20px;
      position: absolute;
      left: 0;
      top: 28%;
      width: 236px;

      div {
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        padding: 23px 0;
      }
    }
  }
}
</style>

