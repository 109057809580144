<template>
  <div class="header">
    <div
      class="classificationDesc"
      v-show="showTooltip"
      @mouseenter="enter()"
      @mouseleave="leave()"
    >
      <div class="categoryLeft">
        <div class="category" v-for="category in mainCat" :key="category.shopGoodsCatId">
          <h3
            @mouseenter="showCategoryR(category.shopGoodsCatName,category.children,category.shopGoodsCatId)"
          >
            <span>{{ category.shopGoodsCatName }}</span>
            <span>></span>
          </h3>
        </div>
      </div>
      <div class="categoryRight">
        <div class="category">
          <div class="category-title">
            <h3>{{ shopGCatChName }}</h3>
            <div class="viewAll">
              <a @click="goViewAll()">{{ "查看全部" | formatTrans }}</a>
              <img src="@/assets/images/login/arrow.png" style>
            </div>
          </div>
          <div class="cate-sec-wrap">
            <div
              v-for="subCategory in shopGCatChArr"
              :key="subCategory.shopGoodsCatId"
              @click="goProductsDesc(subCategory)"
              class="cate-sec"
            >
              <img :src="subCategory.shopGoodsCatImg">
              <span>{{ subCategory.shopGoodsCatName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="top">
      <div class="top-in">
        <div class="logo-wrap">
          <div class="logo">
            <img @click="goHome" class :src="shopLogoSrc">
          </div>
          <div class="classification" @mouseenter="enter()">
            <img class src="@/assets/images/classification.png">
          </div>
          <div class="zw-space">&nbsp;</div>
        </div>

        <div class="menuLists">
          <div
            v-for="(ele, index) in menuLists"
            :key="index"
            :class="{ active: activeMenuIndex === index }"
            @click="setActiveMenu(index)"
          >{{ ele.name | formatTrans }}</div>
        </div>

        <div class="top-right">
          <div class="searchForm">
            <div
              @mouseleave="showDiv = false"
              :class="showDiv ? 's-input-wrap-show' : 's-input-wrap-hid'"
              class="s-input-wrap"
            >
              <el-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter.native="onSearch"
                class="icon-input"
                @click="onSearch"
                clearable
              ></el-input>
            </div>
            <img src="@/assets/images/search.png" @mouseenter="showDiv = true" alt>
          </div>
          <div class="icons-wrap">
            <div @click="goWishList" class="icons">
              <img class src="@/assets/images/collect.png">
            </div>
            <div @click="peopleCenter" class="icons">
              <img class src="@/assets/images/my.png">
            </div>
            <div @click="goShoppingcart" class="icons">
              <img class src="@/assets/images/shoppingcart.png">
            </div>
            <div class="lan-icons" @mouseenter="lanFrShow = true">
              <img class src="@/assets/images/language.png">
              <span class="lan-val">{{ lanVal }}</span>
            </div>
          </div>
          <div class="lan-sel-frame" v-show="lanFrShow" @mouseleave="lanFrShow = false">
            <div class="sin-frame" @click="selectLan(0)">
              <div class="lan-sel-img-wrap" v-show="lanSelIndex == 0">
                <img src="@/assets/images/lan-seled.png" alt>
              </div>
              <span class="zw-sign" v-show="lanSelIndex != 0">&nbsp;</span>
              <span class="sel-lan-val">USA / English</span>
            </div>
            <div class="sin-frame" @click="selectLan(1)">
              <div class="lan-sel-img-wrap" v-show="lanSelIndex == 1">
                <img src="@/assets/images/lan-seled.png" alt>
              </div>
              <span class="zw-sign" v-show="lanSelIndex != 1">&nbsp;</span>
              <span class="sel-lan-val">中国 / 中文</span>
            </div>
            <div class="sin-frame" @click="selectLan(2)">
              <div class="lan-sel-img-wrap" v-show="lanSelIndex == 2">
                <img src="@/assets/images/lan-seled.png" alt>
              </div>
              <span class="zw-sign" v-show="lanSelIndex != 2">&nbsp;</span>
              <span class="sel-lan-val">한국 / 한국어</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { globalVari } from "@/utils/variable";
import { getShopCatsS } from "@/api/index";
import { getTrans } from "@/api/set.js";

export default {
  name: "Header",
  props: {
    categoryTree: Array
  },
  data() {
    return {
      shopLogoSrc: "",
      shopLogoName: "",
      goodsCatId: "",
      shopGCatChName: "",
      shopGCatChArr: [],
      mainCat: [], // 主分类
      searchQuery: "",
      activeMenuIndex: 0, // 当前激活菜单的索引
      showDiv: false,
      showTooltip: false,
      menuLists: [
        {
          id: 1,
          name: "首页"
        },
        {
          id: 2,
          name: "品牌店铺"
        },
        {
          id: 3,
          name: "精选系列"
        }
      ],
      shopId: globalVari.shopId, // 固定
      lanFrShow: false,
      lanSelIndex: 0,
      lanVal: "En"
    };
  },

  created() {
    this.getShopCats();
    if (sessionStorage.getItem("pcLanguage") == "en") {
      this.lanSelIndex = 0;
      this.lanVal = "En";
    } else if (sessionStorage.getItem("pcLanguage") == "cn") {
      this.lanSelIndex = 1;
      this.lanVal = "Cn";
    } else if (sessionStorage.getItem("pcLanguage") == "kor") {
      this.lanSelIndex = 2;
      this.lanVal = "Kor";
    } else {
      this.lanSelIndex = 0;
      this.lanVal = "En";
    }
  },

  activated() {
    if (sessionStorage.getItem("pcLanguage") == "en") {
      this.lanSelIndex = 0;
      this.lanVal = "En";
    } else if (sessionStorage.getItem("pcLanguage") == "cn") {
      this.lanSelIndex = 1;
      this.lanVal = "Cn";
    } else if (sessionStorage.getItem("pcLanguage") == "kor") {
      this.lanSelIndex = 2;
      this.lanVal = "Kor";
    } else {
      this.lanSelIndex = 0;
      this.lanVal = "En";
    }
  },

  watch: {
    $route: {
      handler(to, from) {
        if (to.name == "Home") {
          this.activeMenuIndex = 0;
        } else if (to.name == "BrowseBrand") {
          this.activeMenuIndex = 1;
        } else if (to.name == "SelectedSeries") {
          this.activeMenuIndex = 2;
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    onSearch() {
      this.$router.push({
        path: "/SearchResult",
        query: { searchForm: this.searchQuery, timeData: new Date().getTime() }
      });
    },

    goHome() {
      if (this.$router.currentRoute.path !== "/") {
        this.$router.replace("/");
      }
    },

    selectLan(index) {
      this.lanSelIndex = index;
      this.lanFrShow = false;
      if (index == 0) {
        this.lanVal = "En";
        sessionStorage.setItem("pcLanguage", "en");
      } else if (index == 1) {
        this.lanVal = "Cn";
        sessionStorage.setItem("pcLanguage", "cn");
      } else {
        this.lanVal = "Kor";
        sessionStorage.setItem("pcLanguage", "kor");
      }

      location.reload();
    },

    goViewAll() {
      this.$router.push({
        path: "/ClassificationDescribe",
        query: {
          clType: 1,
          catId: this.goodsCatId,
          clTitle: this.shopGCatChName
        }
      });
    },

    setActiveMenu(index) {
      this.activeMenuIndex = index;
      if (index == 0) {
        if (this.$router.currentRoute.path !== "/") {
          this.$router.replace("/");
        }
      } else if (index == 1) {
        if (this.$router.currentRoute.path !== "/BrowseBrand") {
          this.$router.replace("/BrowseBrand");
        }
      } else if (index == 2) {
        if (this.$router.currentRoute.path !== "/SelectedSeries") {
          this.$router.replace("/SelectedSeries");
        }
      }
    },

    enter() {
      this.showTooltip = true;
      this.shopGCatChName = this.mainCat[0].shopGoodsCatName;
      this.shopGCatChArr = this.mainCat[0].children;
      this.goodsCatId = this.mainCat[0].shopGoodsCatId;
    },

    leave() {
      this.showTooltip = false;
    },

    // 商品二级分类详情
    goProductsDesc(val) {
      this.$router.push({
        path: "/ClassificationDescribe",
        query: {
          clType: 2,
          catId: val.shopGoodsCatId,
          clTitle: val.shopGoodsCatName
        }
      });
    },

    //心愿清单
    goWishList() {
      if (sessionStorage.getItem("token")) {
        if (this.$router.currentRoute.path !== "/WishList") {
          this.$router.replace("/WishList");
        }
      } else {
        if (this.$route.path !== "/Login") {
          this.$router.replace("/Login");
        }
      }
    },

    //个人中心
    peopleCenter() {
      if (sessionStorage.getItem("token")) {
        if (this.$route.path !== "/MyAccount") {
          this.$router.replace("/MyAccount");
        }
      } else {
        if (this.$route.path !== "/Login") {
          this.$router.replace("/Login");
        }
      }
    },

    //购物车
    goShoppingcart() {
      if (sessionStorage.getItem("token")) {
        if (this.$route.path !== "/ViewCart") {
          this.$router.replace("/ViewCart");
        }
      } else {
        if (this.$route.path !== "/Login") {
          this.$router.replace("/Login");
        }
      }
    },

    // 查询店铺自定义分类
    getShopCats() {
      let obj = {
        shopId: this.shopId
      };
      getShopCatsS(obj).then(res => {
        if (res.code == 1 && res.data) {
          this.mainCat = res.data.shopSeltCatList;
          this.shopLogoSrc = res.data.shopBase.shopLogo;

          sessionStorage.setItem(
            "shopLogoName",
            res.data.shopBase.shopLogoName
          );
        } else {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box"
          });
        }
      });
    },

    showCategoryR(param1, param2, id) {
      this.shopGCatChName = param1;
      this.shopGCatChArr = param2;
      this.goodsCatId = id;
    }
  }
};
</script>

<style lang="scss" scoped>
.clearfix::after {
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

.header {
  .classificationDesc {
    position: fixed;
    background-color: #fff;
    border: 1px solid #ccc;
    // padding: 35px 0px;
    z-index: 10;
    top: 80px;
    z-index: 99999;
    width: 100%;
    display: flex;
    overflow-x: auto;

    .categoryLeft {
      background: #f3f3f5;
      text-align: right;
      padding: 28px 0;
      width: 29%;
      flex-shrink: 0;

      .category {
        display: flex;
        justify-content: flex-end;
        margin-right: 30px;

        h3 {
          width: 180px;
          padding: 10px 0;
          color: #8c8c8d;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          flex-shrink: 0;
        }

        h3:hover {
          color: #313131;
        }
      }
    }

    @media screen and (max-width: 735px) {
      .categoryLeft {
        padding-left: 30px;
      }
    }

    .categoryRight {
      background: #fff;
      text-align: right;
      padding: 35px 0;

      span {
        float: right;
      }

      .category {
        text-align: left;
        padding-left: 20px;

        .category-title {
          width: 1100px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          h3 {
            padding: 10px 0;
            color: #242424;
          }

          .viewAll {
            display: flex;
            margin-right: 10px;

            a {
              text-decoration: underline; /* 确保下划线 */
            }

            img {
              margin-left: 6px;
              width: 20px;
              height: 15px;
            }
          }
        }

        .cate-sec-wrap {
          width: 1100px;
          display: flex;
          flex-wrap: wrap;
        }

        .cate-sec {
          cursor: pointer;
          width: 274px;
          height: 80px;
          border-radius: 12px;
          background-color: rgba(243, 243, 245, 1);
          padding: 0 20px;
          margin-left: 15px;
          margin-right: 0;
          display: flex;
          align-items: center;

          img {
            width: 80px;
            float: left;
          }

          span {
            width: 50%;
            padding-left: 20px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .cate-sec:nth-child(n + 3) {
          margin-top: 15px;
        }

        .cate-sec:nth-child(3) {
          margin-top: 0;
        }

        .cate-sec:nth-child(3n + 1) {
          margin-left: 0;
        }
      }
    }
  }

  .top {
    box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 99999;
    top: 0;
    // min-width: 60%;
    height: 80px;
    width: 100%;
    margin: 0 auto;
    background: rgba(255, 255, 255, 1);

    .top-in {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .logo-wrap {
        display: flex;

        .zw-space {
          width: 217px;
        }
      }
    }

    .logo {
      cursor: pointer;
      text-align: center;
      vertical-align: bottom;
      padding-right: 30px;
      padding-top: 25px;

      img {
        height: 30px;
        width: 100px;
      }
    }

    .classification {
      cursor: pointer;
      float: left;
      text-align: center;
      vertical-align: bottom;
      padding-right: 10px;
      padding-top: 32px;
      img {
        height: 16px;
        width: 20px;
      }
    }

    .menuLists {
      display: flex;
      justify-content: space-around;
      line-height: 80px;

      div {
        list-style: none;
        cursor: pointer;
        color: #555;
        margin-right: 25px;
      }

      div:nth-child(3) {
        margin-right: 0;
      }

      .active {
        color: #333;
        font-weight: 550;
      }
    }

    .top-right {
      display: flex;
      align-items: center;
      position: relative;

      .searchForm {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 20px;

        .s-input-wrap {
          width: 217px;
        }

        .s-input-wrap-show {
          visibility: visible;
        }

        .s-input-wrap-hid {
          visibility: hidden;
        }

        img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
        }
      }

      .icons-wrap {
        display: flex;
        align-items: center;

        .icons {
          cursor: pointer;
          margin: 0 20px;
        }

        .icons:last-child {
          margin-right: 0;
        }

        img {
          width: 20px;
          height: 20px;
        }

        .lan-icons {
          display: flex;
          align-items: center;
          margin-left: 20px;
          cursor: pointer;

          .lan-val {
            color: rgba(0, 0, 0, 0.85);
            font-size: 15px;
            margin-left: 7px;
          }
        }
      }

      .lan-sel-frame {
        position: absolute;
        right: -33px;
        bottom: -117px;
        width: 160px;
        height: 146px;
        background: url("@/assets/images/sel-lan-frame2.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .sin-frame {
          display: flex;
          align-items: center;
          margin-top: 5px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          width: 128px;
          margin: 0 auto;
          height: 32px;
          cursor: pointer;

          .lan-sel-img-wrap {
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 12px;
              height: 8px;
              margin-left: 8px;
            }
          }

          .zw-sign {
            width: 24px;
          }

          .sel-lan-val {
            margin-left: 4px;
          }
        }

        .sin-frame:nth-child(1) {
          margin-top: 26px;
        }
      }
    }
  }

  .icon-input .el-input__suffix .el-input__suffix-inner {
    font-size: 20px; /* 设置图标的大小 */
  }

  ::v-deep .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 40px;
    font-size: 23px;
    color: #2c2c2c;
  }
}
</style>