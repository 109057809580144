<template>
    <div class="SupRTSelect">
      <Header/>
      <div class="top-space-line"></div>
      <div class="order-re-con">
  
        <div class="order-info">
          <div class="info-bot">
            <div class="goods-img">
                <img src="https://hdy.dd33.cn/images/Yellowsofa.png" alt>
            </div>
            
            <div class="name-attr-price">
            <div class="goods-title">{{ 'asoidfhaoidnaskdnhoiasndlkan' | formatTrans}}</div>
            <div class="goods-attr-wrap">
                <div class="color-wrap">
                    <span>{{ 'Color' | formatTrans}}: </span>
                    <img src="https://hdy.dd33.cn/images/Yellowsofa.png" alt>
                </div>
                <div>{{ 'Size' | formatTrans}}: {{ '2.8m' | formatTrans}}</div>
                <div>{{ 'Quantity' | formatTrans}}: 1</div>
            </div>
            <div class="goods-price">$39</div>
            </div>
          </div>
          <div class="info-bot">
            <div class="goods-img">
                <img src="https://hdy.dd33.cn/images/Yellowsofa.png" alt>
            </div>
            
            <div class="name-attr-price">
            <div class="goods-title">{{ 'asoidfhaoidnaskdnhoiasndlkan' | formatTrans}}</div>
            <div class="goods-attr-wrap">
                <div class="color-wrap">
                    <span>{{ 'Color' | formatTrans}}: </span>
                    <img src="https://hdy.dd33.cn/images/Yellowsofa.png" alt>
                </div>
                <div>{{ 'Size' | formatTrans}}: {{ '2.8m' | formatTrans}}</div>
                <div>{{ 'Quantity' | formatTrans}}: 1</div>
            </div>
            <div class="goods-price">$39</div>
            </div>
          </div>
        </div>

        <div class="ser-type">
            <div class="more-img">
              <img :src="moreImg" />
            </div>
            <div class="s-title">
                <span class="left">{{ '服务' | formatTrans }}</span>
                <span class="right">{{ '类型' | formatTrans }}</span>
            </div>
            <div class="ser-type-in">
                <div class="ser-t-left">
                    <div class="fir-line">
                        <span>{{ '退款' | formatTrans }}({{ '无需退货' | formatTrans }})</span>
                    </div>
                    <div class="sec-line" :class="lanHj == 2 ? 'spec-sec' : ''">
                        <span>{{ '未收到货物，或与卖方协商同意不退货而只退款.' | formatTrans  }}</span>
                    </div>
                    <div class="third-line" :class="lanHj == 2 ? 'spec-third' : ''">
                        <span>{{ 'Confirm' | formatTrans }}</span>
                    </div>
                </div>
                <div class="ser-t-right">
                    <div class="fir-line">
                        <span>{{ '退货 并且 退款' | formatTrans }}</span>
                    </div>
                    <div class="sec-line">
                        <span>{{ '收到货物，需要退回收到的货物.' | formatTrans }}</span>
                    </div>
                    <div class="third-line">
                        <span>{{ 'Confirm' | formatTrans }}</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="bot-space-line"></div>
      <Footer/>
    </div>
</template>
<script>
  import { globalVari } from "@/utils/variable";
  import { getshopBrand } from "@/api/index";
  import Header from "@/views/layout/Header.vue";
  import Footer from "@/views/layout/Footer.vue";
  import { getTrans } from "@/api/set.js";
  import moreImg from "@/assets/images/more-img.png";
  
  export default {
    name: "SupRTSelect",
    components: {
      Header,
      Footer
    },

    data() {
      return {
        moreImg,
        goodsInfo: "",
        lanHj: 1,
      }
    },

    mounted() {
        this.getLanHJ()
    },

    methods: {
      getLanHJ() {
        if (sessionStorage.getItem('pcLanguage') == 'en') {
          this.lanHj = 1
        } else if (sessionStorage.getItem('pcLanguage') == 'cn') {
          this.lanHj = 2
        } else if (sessionStorage.getItem('pcLanguage') == 'kor') {
          this.lanHj = 3
        } else {
          this.lanHj = 1
        }
      },

      // 获取品牌店铺
      fetchData() {
        let obj = {
          shopId: this.shopId
        };
        getshopBrand(obj).then(res => {
          if (res.code == 1 && res.data) {
            this.hasDatas = false;
            this.BrowseBrandList = res.data;
          } else {
            this.hasDatas = true;
            this.$message({
              message: getTrans(res.message),
              type: "error",
              duration: 3000,
              customClass: "auto-mes-box"
            });
          }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .SupRTSelect {
    
    .top-space-line {
        height: 80px;
    }

    .bot-space-line {
      height: 100px;
    }
  
    .order-re-con {
      width: 1200px;
      margin: 0 auto;
    }
  
    .order-info {
      margin-top: 54px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.15);

      .info-bot {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding: 24px 0;

        .goods-img {
            width: 110px;
            margin-left: 24px;

            img {
                width: 100%;
            }
        }

        .name-attr-price {
            display: flex;
            flex-direction: column;
            margin-left: 13px;

            .goods-title {
                color: rgba(0, 0, 0, 0.85);
                font-size: 18px;
                font-weight: bold;
            }

            .goods-attr-wrap {
                display: flex;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.85);
                margin-top: 16px;

                div {
                    margin-left: 48px;
                }

                div:nth-child(1) {
                    margin-left: 0;
                }

                .color-wrap {
                    display: flex;
                    align-items: center;

                    img {
                        width: 40px;
                        height: 24px;
                    }
                }
            }

            .goods-price {
                color: rgba(127, 73, 42, 1);
                font-size: 18px;
                margin-top: 16px;
            }
        }
      }

      .info-bot:last-child {
        border-bottom: none;
      }
    }

    .ser-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-top: none;
        padding: 17px 24px;
        position: relative;

        .more-img {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 54px;
          height: 33px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .s-title {
            color: rgba(0, 0, 0, 0.85);
            font-size: 18px;

            .right {
                margin-left: 10px;
            }
        }

        .ser-type-in {
            margin-top: 24px;
            display: flex;
            justify-content: space-between;

            .ser-t-left , .ser-t-right {
                width: 569px;
                height: 350px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;

                .fir-line {
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 18px;
                    font-weight: bold;
                }

                .sec-line {
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 12px;
                    line-height: 20px;
                    width: 360px;
                    margin: 0 auto;
                    margin-top: 32px;
                }

                .spec-sec {
                    margin-top: 42px;
                }

                .third-line {
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 16px;
                    margin: 0 auto;
                    margin-top: 32px;
                    font-weight: bold;
                    cursor: pointer;
                    width: 61px;

                    span {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.85);
                    }
                }

                .spec-third {
                    margin-top: 42px;
                }
            }
            
            .ser-t-right {
                .sec-line {
                    margin-top: 42px;
                }

                .third-line {
                    margin-top: 42px;
                }
            }
        }
    }
  }
  </style>
    
    