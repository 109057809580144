import axios from 'axios'
import { Message } from 'element-ui'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_URL, // api 的 base_url
  // baseURL: '/api/',//本地调试使用
  timeout: 100000, // 请求超时时间
  withCredentials: true,
})

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非1是抛错
     */
    const res = response.data
    if (res.code == 4 || res.code == 10015 || res.code == 10012 || res.code == 10017) {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject('error')
    } else if (res.code == 3) {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      })
    } else {
      return response.data
    }
  },
  error => {
     // for debug
    // 当响应异常时做处理
    if (error && error.response) {
      switch (error.response.status) {
        case 400: {
          // error.message = '请求错误(400)'
          Message({
            message: '请求错误(400)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 401: {
          // error.message = '未授权，请重新登录(401)'
          Message({
            message: '未授权，请重新登录(401)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 403: {
          Message({
            message: '拒绝访问(403)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 404: {
          Message({
            message: '请求出错(404)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 408: {
          Message({
            message: '请求超时(408)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 500: {
          Message({
            message: '服务器错误(500)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 501: {
          Message({
            message: '服务未实现(501)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 502: {
          Message({
            message: '网络错误(502)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 503: {
          Message({
            message: '服务不可用(503)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 504: {
          Message({
            message: '网络超时(504)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 505: {
          Message({
            message: 'HTTP版本不受支持(505)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        default: {
          Message({
            message: `连接出错(${error.response.status})!`,
            type: 'error',
            duration: 5 * 1000
          })
        }
      }
    } else {
      Message({
        message: `连接服务器失败!`,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
