<template>
  <div>
    <Header />
    <p style="text-align: center; font-size: 30px; padding-top: 200px">
      THANK YOU FOR REGISTERING WITH US.
    </p>
    <p style="text-align: center; font-size: 20px; padding-top: 20px">
      PLEASE WAIT FOR 5 SECONDS TO REDIRECT TO THE HOME PAGE
    </p>

    <div class="countdown">
      <span class="circle" v-if="countdown">{{ countdown }}</span>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
export default {
  name: "RegisterSuccess",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      rules: {},
      logoutForm: {},
      isEdit: true,
      emailistrue: true,
      phoneistrue: true,
      phonecodeistrue: true,
      emailcodeistrue: true,
      countdown: 3,
      timer: null,
    };
  },

  created() {
    this.startCountdown();
  },

  methods: {
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.redirect();
        }
      }, 1000);
    },
    redirect() {
      this.$router.push("/home");
      clearInterval(this.timer);
    },
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },

    emailhandleInput(value) {
      if (value) {
        this.emailistrue = false;
      }
      if (!value) {
        this.emailistrue = true;
      }
      console.log("输入的内容：", value);
      // 这里可以根据value来判断是否输入了内容
    },
    phonehandleInput(value) {
      if (value) {
        this.phoneistrue = false;
      }
      if (!value) {
        this.phoneistrue = true;
      }
      console.log("输入的内容：", value);
      // 这里可以根据value来判断是否输入了内容
    },
    phoneCodeInput(value) {
      if (value) {
        this.phonecodeistrue = false;
      }
      if (!value) {
        this.phonecodeistrue = true;
      }
      console.log("输入的内容：", value);
      // 这里可以根据value来判断是否输入了内容
    },
    emailCodeInput(value) {
      if (value) {
        this.emailcodeistrue = false;
      }
      if (!value) {
        this.emailcodeistrue = true;
      }
      console.log("输入的内容：", value);
      // 这里可以根据value来判断是否输入了内容
    },

    // 编辑
    isEditFn() {
      // if (this.isEdit) {
      //   this.isEdit = false;
      // }
      this.isEdit = false;
    },
    CancelFn() {
      this.isEdit = true;
    },
    open4() {
      this.$message.error("错了哦，这是一条错误消息");
    },
  },
};
</script>

<style lang="scss" scoped>
.my {
  ::v-deep .el-form-item__content,
  ::v-deep .el-checkbox__label {
    font-size: 18px !important;
  }
  p {
    text-align: center;
  }
  .titleBig {
    font-size: 30px;
    font-weight: 900;
  }
  .tips {
    text-align: left;
    border: 1px solid #ff5747;
    color: #ff5747;
    border-radius: 10px;
    padding: 20px;
    margin-top: 40px;
  }
  .line {
    color: #b98465;
    padding: 50px;
    text-align: center;
    font-weight: 550;
    .lineO {
      float: left;
      height: 2px;
      padding-top: 10px;
      border-bottom: 1px solid #b98465;
    }
  }
  .linkBox {
    display: flex;
    justify-content: center;
    justify-items: center;
    justify-content: space-around;
    .linkBoxC {
      cursor: pointer;
      width: 100%;
      // text-align: center;
      border: 1px solid #d9d9d9;
      color: #262626;
      border-radius: 10px;
      padding: 20px;
      margin: 10px;
      font-weight: 550;
      display: flex;

      img {
        width: 28px;
        display: block;
        padding-left: 150px;
        float: right;
        text-align: right;
      }
      div {
        font-size: 20px;
        // width: 50%;
        justify-content: center;
        justify-items: center;
        justify-content: space-around;
      }
      div:first-child {
        width: 80%;
      }
      div:last-child {
        width: 20%;
      }
    }
    .linkBoxC:first-child {
      margin-left: 0 !important;
    }
    .linkBoxC:last-child {
      margin-right: 0 !important;
    }
  }
  .yourS {
    border-bottom: 1px solid #d9d9d9;
    color: #262626;
    // padding: 20px 0;
    margin: 40px 0;
    font-weight: 550;
    font-size: 20px;
    span {
      border-bottom: #b98465 solid 6px;
    }
  }
  .logoutForm {
    a {
      text-decoration: underline; /* 确保下划线 */
      cursor: pointer;
    }
    .el-button.is-round {
      width: 120px;
    }
  }
}
.countdown {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #d9d9d9;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    //   animation: rotate 2s linear infinite;
  }
}
</style>
