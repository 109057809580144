<template>
  <div class="ContactUs">
    <Header/>

    <div class="main">
      <div class="titles">
        <div class="titleBig">Contact Us</div>
      </div>
      <div class="line">
        <img src="@/assets/images/login/login.png" style="width: 100%; ">
      </div>
      <div class="box">
        <el-row class="flexT">
          <el-col :span="8" class="boderL">
            <div class="titleS">{{'与我们交流' | formatTrans}}</div>
            <div
              class="titleLit"
            >{{'周一至周六上午9点至下午8点（东部标准时间）。周日上午10点至下午7点（东部标准时间）。' | formatTrans}}</div>
            <div class="titleM">
              <span class="el-icon-chat-dot-square"></span>
              {{'WHATSAPP CHAT' | formatTrans}}
            </div>
          </el-col>
          <el-col :span="8" class="boderL">
            <div class="titleS">{{'给我们致电' | formatTrans}}</div>
            <div
              class="titleLit"
            >{{'周一至周六上午9点至下午8点（东部标准时间）。周日上午10点至下午7点（东部标准时间）。' | formatTrans}}</div>
            <div class="titleM">
              <span class="el-icon-phone-outline"></span>
              {{'Call Us +1.877.482.2430' | formatTrans}}
            </div>
          </el-col>
          <el-col :span="8" class="boderL">
            <div class="titleS">{{'发邮件' | formatTrans}}</div>
            <div
              class="titleLit"
            >{{'周一至周六上午9点至下午8点（东部标准时间）。周日上午10点至下午7点（东部标准时间）。' | formatTrans}}</div>
            <div class="titleM">
              <span class="el-icon-message"></span>
              {{'US_CC@BABITINO.COM' | formatTrans}}
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <Footer/>
  </div>
</template>
<script>
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      hasAddress: false, //是否有地址
      //密码小眼睛切换状态
      flag: false,
      flagType: "password",
      loginForm: {},
      forgotdialogVisible: false,
      forgotdialogVisibleSend: false,
      forgotForm: {},
      rules: {
        EmailAddress: [
          {
            required: true,
            message: "EmailAddress is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.ContactUs {
  padding: 100px 0;
  .main {
    width: 1200px;
    margin: 0 auto;
    margin-top: 48px;
  }
  .hide {
    visibility: hidden;
  }
  .clearb {
    clear: both;
  }

  .titles {
    text-align: center;
    .titleBig {
      font-size: 40px;
      color: #333;
      font-weight: 900;
    }
    .titleSmall {
      font-size: 14px;
      color: #666;
      margin: 10px 0;
    }
  }
  .line {
    width: 972px;
    margin: 50px auto;

    img {
      width: 100%;
    }
  }
  .box {
    // width: 50%;
    margin: 20px 0;
    // padding: 50px 0;
    font-size: 16px !important;
    // text-align: center;
    // background: #f3f3f5;
    ::v-deep .el-form-item__content,
    ::v-deep .el-checkbox__label {
      font-size: 18px !important;
    }
    .flexT {
      display: flex;
      justify-content: center;
      .boderL {
        width: 386px;
        height: 500px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(0, 0, 0, 0.15);
        margin: 0 10px;
        .titleS {
          font-size: 24px;
          // text-align: center;
          color: rgba(0, 0, 0, 0.85);
          padding: 48px 28px;
        }
        .titleLit {
          color: rgba(0, 0, 0, 0.85);
          font-size: 12px;
          padding: 48px 28px;
          width: 260px;
          line-height: 20px;
        }
        .titleM {
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
          padding: 48px 28px;
          text-decoration: underline;
          cursor: pointer;
          span {
            text-decoration: underline;
          }
        }
      }
    }

    //   border: 1px solid #ddd;
  }
}
</style>

