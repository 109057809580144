<template>
  <div class="ViewCart">
    <Header/>
    <div>
      <div class="mainBox">
        <div>
          <div>
            <div class="viewCartNum">{{'购物车' | formatTrans}} ({{numberOfValidProducts}})</div>
          </div>
          <div>
            <el-table :data="cartLists" style="width: 100%" fit>
              <el-table-column label width="872">
                <template slot-scope="scope">
                  <el-table
                    empty-text="No data"
                    ref="multipleTable"
                    :data="scope.row.goodsDetailsList"
                    style="width: 100%"
                    fit
                    @selection-change="handleSelectionChange"
                  >
                    <el-table-column type="selection" width="45" label="Select All"></el-table-column>
                    <el-table-column prop="goodsImage" label="Select All" width="125">
                      <template slot-scope="scope">
                        <img
                          class="goodsImage"
                          :src="scope.row.goodsImage"
                          @click="goProductsDesc(scope.row.goodsId)"
                        >
                      </template>
                    </el-table-column>
                    <el-table-column prop="goodsImage" label width="320">
                      <template slot-scope="scope">
                        <span class="goodsName">{{scope.row.goodsName}}</span>
                        <br>
                        <div class="goodsSpecChineseValue">
                          <div
                            v-for="(item,index) in scope.row.specification"
                            :key="index+11"
                            class="fl padR30"
                            v-show="item.paramName == 'Colour'"
                          >
                            {{ item.paramName | formatTrans}}:
                            <el-tooltip :content="item.paramValue" placement="top">
                              <img :src="item.paramImage" style>
                            </el-tooltip>
                          </div>
                          <div
                            v-for="(item,index) in scope.row.specification"
                            :key="index+22"
                            class="fl padR30"
                            v-show="item.paramName == 'Size'"
                          >{{ item.paramName | formatTrans}}: {{ item.paramValue | formatTrans}}</div>
                        </div>
                        <br>
                        <div class="clearb"></div>
                        <div class="selectSpecifcations">
                          <a @click="selectSpecifcations(scope.row)">{{'选择规格' | formatTrans}}</a>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="goodsPrice" label width>
                      <template slot-scope="scope">
                        <!-- <span class="fl">{{'goodsPrice' | formatTrans}}:</span> -->
                        <span class="goodsPrice fr">{{ curSymbol + scope.row.goodsPrice }}</span>
                        <div class="clearb"></div>
                        <div class="quantity">
                          <span class="quantityBtn fr">
                            <!-- <span class="fl">{{'quantity' | formatTrans}}:</span> -->
                            <div class="fl" @click="handleSubtract(scope.$index, scope.row)">-</div>
                            <span class="fl">{{ scope.row.goodsNum }}</span>
                            <div class="fl" @click="handleAdd(scope.$index, scope.row)">+</div>
                          </span>
                        </div>
                        <div class="operation">
                          <img class src="@/assets/images/collect.png">
                          <a
                            style="margin-right:20px;"
                            @click="addFN(scope.row)"
                          >{{'加入心愿清单' | formatTrans}}</a>
                          <a @click="RemoveFN(scope.row.goodsId)">{{'删除' | formatTrans}}</a>
                        </div>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column label="小计">
                        <template slot-scope="scope">
                          <span class="price">${{ scope.row.goodsNum * scope.row.goodsPrice }}</span>
                        </template>
                    </el-table-column>-->
                  </el-table>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="lineSs"></div>
        </div>
        <div>
          <div class="orderOverview">
            <div class="orderOverviewTitle">{{'订单概述' | formatTrans}}</div>
            <div class="lineS"></div>
            <div class>
              <div class="fl Amount">{{'金额' | formatTrans}}</div>
              <div class="fr">{{ curSymbol }}{{ totalAmount ? totalAmount : 0 }}</div>
            </div>
            <div class="clearb"></div>
            <div class>
              <div class="fl Freight">{{'运费' | formatTrans}}</div>
              <div class="fr Freight">{{ curSymbol }}0</div>
            </div>
            <div class="clearb"></div>
            <div class>
              <div class="fl">{{'税费' | formatTrans}}</div>
              <div class="fr">{{ curSymbol }}0</div>
            </div>
            <div class="clearb"></div>
            <div class="lineS"></div>
            <div class>
              <div class="fl Subtotal">{{'小计' | formatTrans}}:</div>
              <div class="fr priceSS">{{ curSymbol }}{{ totalAmount ? totalAmount : 0 }}</div>
            </div>
            <div class="clearb"></div>
            <div class="lineS"></div>
            <div class="btns">
              <el-tooltip
                :manual="!isDisabled"
                class="item"
                effect="dark"
                :content="dynamicContent"
                placement="top"
              >
                <el-button
                  :disabled="isDisabled"
                  style="width:100%;"
                  type="primary"
                  round
                  class="button"
                  @click="GoToCheckout"
                >{{'前往结账' | formatTrans}}</el-button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    <!-- 右侧弹框 -->
    <div class="cart" v-if="dialogVisible">
      <div class="cartTitle">
        <div>{{'选择规格' | formatTrans}}</div>
        <div class="closeDialog" @click="closeDialog">
          <img src="@/assets/images/close.png">
        </div>
      </div>
      <div class="clearb"></div>
      <div class="lineS" style="margin-top: 20px;"></div>
      <div>
        <div v-if="goodsImage" class="fl titleGuige" style>
          <img :src="goodsImage" width="90px;">
        </div>
        <span v-if="goodsPrice" class="fl goodsPriceGuige">{{curSymbol + goodsPrice}}</span>
        <div class="clearb"></div>
        <div v-for="(item,index) in guigeData" :key="index">
          <div class="gui-ge-title">{{ item.paramName | formatTrans}}</div>
          <ul class="gui-ge" v-show="item.paramName == 'Size'">
            <li
              :class="item.selected == items ? 'gui-ge-selected': ''"
              v-for="(items,indexs) in item.paramNameValues"
              :key="indexs"
              @click="selectGuiGe(item.paramName,items)"
            >{{items | formatTrans}}</li>
          </ul>
          <ul class="gui-ge-colour" v-show="item.paramName == 'Colour'">
            <li
              :class="item.selected == items.Colour ? 'gui-ge-selected': ''"
              v-for="(items,indexs) in item.paramNameValuesActivity"
              :key="indexs"
              @click="selectGuiGe(item.paramName,items.Colour)"
            >
              <el-tooltip :content="items.Colour" placement="top">
                <img :src="items.paramImage" style="width:100%;">
              </el-tooltip>
            </li>
          </ul>
        </div>
      </div>
      <div class="clearb"></div>

      <div class="btns">
        <el-button
          style="width:100%; margin-top:40px;"
          type="primary"
          round
          class="button"
          @click="Confirm"
        >{{'确认' | formatTrans}}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  queryShoppingCart,
  addfavoritesGoods,
  batchDeleteGoods,
  getgoodsdetail,
  selectGoodsAttrs,
  changeGoodsSpec,
  logout
} from "@/api/index";
import { getTrans } from "@/api/set.js";

import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
export default {
  name: "ViewCart",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      dynamicContent: "Please select the product first",
      flag: false, // 是否是第一次打开详情页面
      goodsPrice: "",
      goodsImage: "",
      guigeData: [],
      detailData: null, // 详情,
      rowData: {}, //行数据
      heightNew: 0,
      dialogVisible: false,
      multipleSelection: [],
      dataArr: [],
      goodsListsdatas: [],
      isDisabled: true,
      formInline: {},
      cartLists: [],
      tableData: [],
      goodsNum: 1,
      numberOfValidProducts: 0,
      curSymbol: ""
    };
  },

  created() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
    this.getCartList();
  },

  activated() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
  },

  computed: {
    totalAmount: function() {
      return this.dataArr.reduce((total, item) => {
        return total + item.goodsNum * item.goodsPrice;
      }, 0);
    }
  },

  mounted() {
    this.heightNew = `calc(100vh - 380px)`; //小轮播图的高度
  },

  methods: {
    // 跳转商品详情
    goProductsDesc(id) {
      this.$router.push({
        path: "/productsDesc",
        query: {
          goodsId: id
        }
      });
    },
    selectSpecifcations(row) {
      console.log("row===>", row);
      this.rowData = row;
      this.getProductsDetails(row.goodsId);
      this.dialogVisible = true;
    },
    // 获取首页商品详细信息
    getProductsDetails(id) {
      let data = {
        goodsId: id
      };

      // 查询所有规格
      getgoodsdetail(data).then(res => {
        if (res.code == 1 && res.data) {
          this.guigeData = res.data.gdsCommon.specParamList;
          this.detailData = res.data.gdsCommon;
          // this.goodsPrice = res.data.goodsBases.goodsPrice;
          // this.commonId = res.data.gdsCommon.commonId;
          // this.guigeData = res.data.gdsCommon.specParamList;
          // this.guigeData = this.specParamList;
          let specData = res.data.goodsBases.specValueList;
          for (let i = 0; i < this.guigeData.length; i++) {
            this.guigeData[i].selected = specData[i].paramValue;
          }
          // this.$message({
          //   message: res.message,
          //   type: "success",
          //   duration: 3000
          // });
        } else {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box"
          });
        }
      });
    },
    // 选择商品规格
    selectGuiGe(param1, param2) {
      let newObj = {};
      for (let i = 0; i < this.guigeData.length; i++) {
        if (this.guigeData[i].paramName == param1) {
          this.$set(this.guigeData[i], "selected", param2);
          this.$forceUpdate();
        }
      }

      let specData = [];

      for (let i = 0; i < this.guigeData.length; i++) {
        specData.push({
          paramName: this.guigeData[i].paramName,
          paramValue: this.guigeData[i].selected
        });
      }

      this.quantity = 1;

      let data = {
        commonId: this.detailData.commonId,
        specValues: specData
      };

      selectGoodsAttrs(data).then(response => {
        if (response.code == 1) {
          this.flag = true;
          this.goodsPrice = response.data.goodsPrice;
          this.goodsImage = response.data.goodsImage;
          this.goodsId = response.data.goodsId;

          // this.detailData = response.data;
          this.$set(this.detailData, "goodsId", response.data.goodsId);
          this.$set(this.detailData, "goodsImage", response.data.goodsImage);
          this.selectGuiGedetailData = response.data.goodsImages;
        } else {
          this.$message({
            message: getTrans(response.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });
          this.flag = false;
        }
      });
    },
    // 确认更改规格
    Confirm() {
      let obj = {
        token: sessionStorage.getItem("token"),
        goodsId: this.goodsId,
        goodsNum: 1
      };
      changeGoodsSpec(obj).then(response => {
        if (response.code == 1) {
          this.getCartList();
          this.dialogVisible = false;
          this.$message({
            message: getTrans("success"),
            type: "success",
            customClass: "auto-mes-box",
            duration: 3000
          });
        } else if (response.code == 10016) {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.$message({
            message: getTrans(response.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });
          this.getCartList();
          this.dialogVisible = false;
        }
      });

      this.goodsPrice = "";
      this.goodsImage = "";
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    handleAdd(index, row) {
      row.goodsNum++;
    },
    handleSubtract(index, row) {
      if (row.goodsNum >= 2) {
        row.goodsNum--;
      } else {
        row.goodsNum = 1;
      }
    },
    addFN(val) {
      //mall-order/order/userCart/batchDeleteGoods
      let data = {
        commonId: val.commonId,
        goodsId: val.goodsId,
        token: sessionStorage.getItem("token")
      };
      addfavoritesGoods(data).then(response => {
        if (response.code == 1) {
          this.$message({
            message: getTrans("success"),
            type: "success",
            customClass: "auto-mes-box",
            duration: 3000
          });
          this.getCartList();
        } else if (response.code == 10016) {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.$message({
            message: getTrans(response.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });
        }
      });
    },
    RemoveFN(id) {
      let data = {
        goodsId: [id],
        token: sessionStorage.getItem("token")
      };

      batchDeleteGoods(data).then(response => {
        if (response.code == 1) {
          this.$message({
            message: getTrans("success"),
            type: "success",
            customClass: "auto-mes-box",
            duration: 3000
          });
          this.getCartList();
        } else if (response.code == 10016) {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.$message({
            message: getTrans(response.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });
        }
      });
      this.getCartList();
    },
    // 购物车列表
    getCartList() {
      let data = {
        token: sessionStorage.getItem("token")
      };
      queryShoppingCart(data).then(response => {
        if (response.code == 1 && response.data.list) {
          this.numberOfValidProducts = response.data.numberOfValidProducts;
          this.cartLists = response.data.list;
        } else if (response.code == 10016) {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.$message({
            message: getTrans(response.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });
    },

    GoToCheckout() {
      this.dataArr;
      sessionStorage.setItem("goodsListsdatas", JSON.stringify(this.dataArr));
      this.$router.replace({
        path: "/ShippingAddress"
      });
    },
    handleSelectionChange(val) {
      if (val != "") {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }

      this.multipleSelection = val;
      this.dataArr = val;
    }
  }
};
</script>
<style lang="scss" scoped>
.ViewCart {
  padding: 100px 0;
  ::v-deep .el-table .cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .lineSs {
    // width: 400px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.45);
  }
  ::v-deep .el-table .el-table__cell:first-child {
    padding: 0;
  }

  ::v-deep .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid rgba(0, 0, 0, 0.45) !important;
  }

  ::v-deep .el-table::before {
    background-color: #fff;
  }
  .mainBox {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    .goodsImage {
      width: 110px;
      border-radius: 8px;
      background-color: rgba(243, 243, 245, 1);
      margin-bottom: 30px;
      cursor: pointer;
    }
    .selectSpecifcations {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      margin-bottom: 40px;
    }
  }
  .goodsSpecChineseValue {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    float: left;
    line-height: 32px;
    padding: 20px 0 10px 0;
    img {
      width: 40px;
      height: 24px;
      vertical-align: middle;
    }
    span {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      width: 82px;
      height: 32px;
      display: block;
      border-radius: 4px;
      background: #f3f3f5;
      margin-left: 10px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      float: left;
    }
  }
  .el-button {
    border: none;
  }
  .goodsName {
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
  }
  .goodsPrice {
    color: rgba(127, 73, 42, 1);
    font-size: 18px;
    margin-top: 20px;
  }
  .orderOverview {
    margin-top: 85px;
    margin-left: 20px;
    width: 240px;
    border-radius: 8px;
    background-color: rgba(243, 243, 245, 1);
    background: #f3f3f5;
    padding: 30px 20px;
    .orderOverviewTitle {
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
    }
    .Amount {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }
    .Freight {
      padding: 12px 0;
    }
    .Subtotal {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }
    .lineS {
      border-bottom: 1px solid #979799;
      margin: 20px 0;
    }
    .priceSS {
      color: rgba(127, 73, 42, 1);
      font-size: 18px;
    }
    .btns {
      .button {
        color: rgba(255, 255, 255, 1);
        background-color: #b98465;
        border-color: #b98465;
        font-size: 16px !important;
        margin-top: 20px;
      }
    }
  }
  .nameS {
    color: #373737;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 0;
  }
  .priceS {
    color: #8b5a3e;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 0;
  }
  .viewCartNum {
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    text-align: center;
    margin: 20px 0;
  }
  .quantity {
    margin: 10px 0;
    .quantityBtn {
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
      div {
        cursor: pointer;
      }
      span {
        padding: 0 20px;
        // line-height: 40px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
      }
    }
  }
  .operation {
    margin-top: 50px;
    text-align: right;
    position: relative;
    a {
      text-decoration: underline;
      padding: 10px 0;
      display: block;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }
    img {
      width: 18.21px;
      margin-left: 100px;
      margin-top: 13px;
      position: absolute;
      right: 0;
    }
  }
  .el-table .cell {
    background: #333;
    text-align: center;
    padding: 10px;
  }
  .el-table .cell:last-child {
    text-align: right !important;
  }
  ::v-deep .el-table th.el-table__cell > .cell {
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 18px;
    font-weight: normal;
  }
  .cart {
    overflow-y: auto; /* 自动显示垂直滚动条 */
    .gui-ge-title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
      font-family: PingFangSC-Medium;
      margin-top: 24px;
    }

    .gui-ge {
      margin-top: 8px;
      display: flex;
    }

    .gui-ge li {
      width: 112px;
      height: 44px;
      border-radius: 4px;
      background-color: rgba(243, 243, 245, 1);
      margin-left: 10px;
      text-align: center;
      line-height: 44px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }

    .gui-ge li:nth-child(1) {
      margin-left: 0;
    }

    .gui-ge-selected {
      border: 1px solid #b98465;
    }

    .gui-ge-colour {
      margin-top: 8px;
      display: flex;
    }

    .gui-ge-colour li {
      width: 64px;
      height: 64px;
      border-radius: 4px;
      background-color: rgba(243, 243, 245, 1);
      margin-left: 10px;
      text-align: center;
      // line-height: 44px;
      cursor: pointer;
      // color: rgba(0, 0, 0, 0.85);
      // font-size: 16px;
    }
    .gui-ge-colour li:nth-child(1) {
      margin-left: 0;
    }
    .gui-ge-selected-colour {
      border: 1px solid #b98465;
      width: 64px;
      height: 64px;
      background: #f3f3f5;
    }

    .btns {
      .button {
        color: rgba(255, 255, 255, 1);
        background-color: #b98465;
        border-color: #b98465;
        font-size: 16px !important;
        margin-top: 20px;
      }
    }
    .titleGuige {
      border-radius: 4px;
      background-color: #f3f3f5;
      width: 90px;
      margin: 30px 10px 0px 0;
    }
    .goodsPriceGuige {
      color: rgba(127, 73, 42, 1);
      font-size: 18px;
      padding-top: 100px;
    }
    .lineS {
      width: 400px;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.45);
    }
    ::v-deep .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf:first-chlid {
      border-bottom: rgba(0, 0, 0, 0.45) !important;
    }
    .gdsCommon {
      width: 100%;
      // display: flex;
      // justify-content: space-between;
      img {
        width: 64px;
        height: 64px;
        vertical-align: bottom;
        border-radius: 4px;
        background-color: rgba(243, 243, 245, 1);
        border: 1px solid rgba(185, 132, 101, 1);
        margin: 10px 10px 10px 0;
      }
      li {
        // margin: 0;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;

        .sizeSty {
          width: 109px;
          height: 46px;
          line-height: 46px;
          text-align: center;
          border-radius: 4px;
          background-color: rgba(243, 243, 245, 1);
          margin: 10px 10px 10px 0;
        }
      }
      li:first-child {
        float: left;
        padding-right: 10px;
      }
      li:last-child {
        // float: right;
      }
    }
    .goodsName {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }
    .goodsImage {
      width: 90px;
      border-radius: 6px;
      background-color: rgba(243, 243, 245, 1);
    }
    .goodsPrice {
      color: rgba(127, 73, 42, 1);
      font-size: 16px;
      padding: 10px 0;
    }
    .QTY {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      padding: 10px 0;
    }
    // border: 1px solid #ccc;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
    width: 400px;
    box-shadow: -6px 0px 26px 0px rgba(0, 0, 0, 0.15);
    background: #fff;
    height: 100vh;
    padding: 20px;
    .cartTitle {
      margin-bottom: 20px;
      div:first-child {
        float: left;
        color: rgba(0, 0, 0, 0.85);
        font-size: 24px;
      }
      div:last-child {
        float: right;
      }
      .closeDialog {
        cursor: pointer;
        width: 16px;
        height: 16px;
        color: rgba(0, 0, 0, 0.85);
        img {
          width: 16px;
        }
      }
    }
    .cartform {
      margin-top: 20px;
    }
  }
}
</style>

