<template>
  <div>
    <!-- my 开始 -->
    <Header/>
    <div class="myc-top-space-line">&nbsp;</div>
    <div class="my">
      <div class="titleBig">{{'我的帐户' | formatTrans}}</div>
      <div
        class="title-remind"
      >{{'请通过编辑您的个人信息告诉我们您的偏好和特殊场合。我们很乐意用独家内容和惊喜分享您的庆祝活动。敬请期待！' | formatTrans}}</div>
      <div
        v-show="emailCode"
        class="tips"
      >{{'确认链接已发送到您的邮箱，请单击它以确认您的帐户。单击此处再次接收确认链接。' | formatTrans}}</div>
      <div class="line">
        <div class="line-lr"></div>
        <div class="logout-btn" @click="skipLogout">{{'退出' | formatTrans}}</div>
        <div class="line-lr"></div>
      </div>
      <div class="linkBox">
        <div class="linkBoxC" @click="OrderHistory">
          <div>{{'历史订单' | formatTrans}}</div>
          <img src="@/assets/images/login/arrow.png">
        </div>
        <div class="linkBoxC" @click="addressBook">
          <div>{{'地址薄' | formatTrans}}</div>
          <img src="@/assets/images/login/arrow.png">
        </div>
        <div class="linkBoxC" @click="changePassword">
          <div>{{'修改密码' | formatTrans}}</div>
          <img src="@/assets/images/login/arrow.png">
        </div>
      </div>
      <div class="login-f-cont">
        <div>
          <div class="yourS">
            <span>{{'个人信息' | formatTrans}}</span>
          </div>
        </div>
        <!-- 第一个Tab的内容 -->
        <el-form :model="accountForm" :rules="rules" ref="accountForm" class="accountForm">
          <el-form-item prop="Title">
            Title
            <el-select
              style="width: 100%"
              v-model="accountForm.userTitle"
              placeholder="please check Title"
              @change="titleChange"
            >
              <el-option
                v-for="dict in title_list"
                :key="dict.value"
                :label="dict.label"
                :value="dict.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="FirstName">
            {{'名' | formatTrans}}
            <el-input v-model="accountForm.firstName" clearable placeholder="First Name"></el-input>
          </el-form-item>
          <el-form-item prop="LastName">
            {{'姓' | formatTrans}}
            <el-input v-model="accountForm.lastName" clearable placeholder="Last Name"></el-input>
          </el-form-item>
          <el-form-item prop="Country">
            {{'国家/地区' | formatTrans}}
            <el-select
              style="width: 100%"
              v-model="accountForm.country"
              placeholder="please check country"
              @change="countryChange"
            >
              <el-option
                v-for="dict in country_list"
                :key="dict.value"
                :label="dict.name_en"
                :value="dict.name_en"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="email">
            {{'邮箱地址' | formatTrans}}
            <el-input
              v-model="accountForm.email"
              @change="emailChange"
              clearable
              placeholder="E-mail Address"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="VerifcationCode">
            Verifcation Code
            <el-row>
              <el-col :span="18">
                <el-input
                  v-model="accountForm.emailCode"
                  clearable
                  placeholder="Verifcation Code"
                ></el-input>
              </el-col>
              <el-col style="text-align:right;" :span="6">
                <a v-show="isEdit2">Edit</a>
              </el-col>
            </el-row>
          </el-form-item>-->
          <el-form-item prop="userMobile">
            {{'手机号码' | formatTrans}}
            <el-input v-model="accountForm.userMobile" clearable placeholder="Phone Number"></el-input>
          </el-form-item>
          <!-- <el-form-item prop="VerifcationCode">
            Verifcation Code
            <el-row>
              <el-col :span="18">
                <el-input
                  v-model="accountForm.smsCode"
                  clearable
                  placeholder="Verifcation Code"
                ></el-input>
              </el-col>
              <el-col style="text-align:right;" :span="6">
                <a v-show="isEdit2">Edit</a>
              </el-col>
            </el-row>
          </el-form-item>-->
          <div class="footerBtn" style>
            <el-button round @click="CancelFn" class="cancelbutton">Cancel</el-button>
            <el-button
              type="primary"
              round
              @click="submitForm"
              class="button"
            >Save</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <Footer/>
    <!-- my 结束 -->
    <div class="myc-bot-space-line">&nbsp;</div>
  </div>
</template>
<script>
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import { getUser, logout } from "@/api/index";
import country from "@/utils/country.json";
const originList = country;
import { getTrans } from "@/api/set.js";

export default {
  name: "MyAccount",
  components: {
    Header,
    Footer
  },
  data() {
    var checkEmail = (rules, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error(getTrans("邮箱不能为空")));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error(getTrans("邮箱账号格式不正确")));
        }
      }, 100);
    };
    var checkPhone = (rules, value, callback) => {
      if (!value) {
        return callback(new Error(getTrans("手机号不能为空")));
      } else {
        callback();
      }
    };
    return {
      emailCode: "",
      country_list: originList,
      isEdit: false,
      isEdit2: true,
      accountForm: {},
      rules: {
        email: [{ required: true, trigger: "blur", validator: checkEmail }],
        userMobile: [
          {
            required: true,
            trigger: "blur",
            validator: checkPhone
          }
        ]
      },
      title_list: [
        {
          value: 0,
          label: "Mr"
        },
        {
          value: 1,
          label: "Mrs"
        },
        {
          value: 2,
          label: "Ms"
        },
        {
          value: 3,
          label: "Mx"
        }
      ],
      userInfo: []
    };
  },

  created() {
    this.getUserInfo();
  },

  methods: {
    // 国家改变
    countryChange(value) {
      this.accountForm.country = value;
    },
    emailChange(value) {
      if (this.isValidEmail(value)) {
        this.isEdit2 = true;
      } else {
        this.$message({
          message: getTrans("邮箱账号格式不正确"),
          type: "error",
          duration: 3000,
          customClass: "auto-mes-box"
        });
      }
    },
    // 邮箱校验
    isValidEmail(email) {
      var regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return regex.test(email);
    },

    // 获取用户信息
    getUserInfo() {
      let data = {
        token: sessionStorage.getItem("token")
      };
      getUser(data).then(res => {
        if (res.code == 1 && res.data) {
          this.accountForm = res.data;
        } else if (res.code == 10016) {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });
    },
    // 退出登录
    skipLogout() {
      let data = {
        token: sessionStorage.getItem("token")
      };

      logout(data).then(res => {
        let that = this;
        if (res.code == 1) {
          this.$message({
            message: getTrans("退出登录成功"),
            type: "success",
            duration: 1500,
            customClass: "auto-mes-box",
            onClose() {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              that.$router.replace("/");
            }
          });
        } else {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });
    },

    // 跳转至历史订单
    OrderHistory() {
      this.$router.push("/OrderHistory");
    },

    // 跳转地址簿
    addressBook() {
      this.$router.push("/AddressBook");
    },

    // 跳转到修改密码
    changePassword() {
      this.$router.push("/ChangePassword");
    },

    // registerForm 表单改变
    titleChange(value) {
      this.accountForm.userTitle = value;
      this.isEdit = true;
    },

    CancelFn() {

    },

    submitForm() {
      this.$message({
        message: getTrans("保存成功"),
        type: "success",
        duration: 3000,
        customClass: "auto-mes-box"
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.myc-top-space-line {
  height: 80px;
}

.myc-bot-space-line {
  height: 100px;
}

.my {
  width: 1200px;
  margin: 0 auto;
  margin-top: 48px;

  ::v-deep .el-form-item__content,
  ::v-deep .el-checkbox__label {
    font-size: 18px !important;
  }

  .footerBtn {
    display: flex;
    justify-content: space-around;
    justify-items: center;

    .cancelbutton {
      color: #2c3e50;
      background-color: #fff;
      border-color: #ccc;
      font-size: 18px !important;
      margin-top: 20px;
    }

    .button {
      color: #fff;
      background-color: #b98465;
      border-color: #b98465;
      font-size: 18px !important;
      margin-top: 20px;
    }
  }

  .titleBig {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
  }

  .title-remind {
    width: 758px;
    margin: 0 auto;
    margin-top: 22px;
    line-height: 24px;
    text-align: center;
  }

  .tips {
    text-align: left;
    border: 1px solid #ff5747;
    color: #ff5747;
    border-radius: 10px;
    padding: 20px;
    margin-top: 40px;
  }

  .line {
    width: 970px;
    height: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 49px;

    .line-lr {
      width: 426px;
      height: 2px;
      border-bottom: 1px solid #b98465;
    }

    .logout-btn {
      padding: 7px 7px;
      border-radius: 15px;
      border: 1px solid #b98465;
      cursor: pointer;
      color: #b98465;
      font-weight: 550;
    }
  }

  .linkBox {
    display: flex;
    justify-content: center;
    justify-items: center;
    justify-content: space-around;

    .linkBoxC {
      cursor: pointer;
      width: 386px;
      height: 88px;
      border: 1px solid #d9d9d9;
      color: #262626;
      border-radius: 10px;
      margin-left: 21px;
      font-weight: 550;
      display: flex;
      justify-content: space-around;
      align-items: center;

      img {
        width: 22px;
        display: block;
        padding-left: 150px;
      }

      div {
        font-size: 20px;
      }
    }

    .linkBoxC:first-child {
      margin-left: 0 !important;
    }

    .linkBoxC:last-child {
      margin-right: 0 !important;
    }
  }

  .yourS {
    border-bottom: 1px solid #d9d9d9;
    color: #262626;
    // padding: 20px 0;
    margin: 40px 0;
    font-weight: 550;
    font-size: 20px;

    span {
      border-bottom: #b98465 solid 6px;
    }
  }

  .accountForm {
    a {
      text-decoration: underline; /* 确保下划线 */
      cursor: pointer;
    }
  }
}
</style>