<template>
  <div class="SelectedSeries">
    <Header/>
    <div class="s-top-space-line"></div>
    <div class="hasDatas" v-show="hasDatas">{{ "我们找不到任何东西" | formatTrans }}</div>
    <div class="f-row">
      <div class="content-box" v-for="(item,index) in SelectedSeriesList" :key="index">
        <div class="fcetBg" @click="goSelectedSeriesDetail(item)">
          <img class="img-seal" :src="item.bgUrl" alt>
        </div>
        <div class="fcet">{{item.title }}</div>
      </div>
    </div>
    <div class="s-bot-space-line"></div>
    <div class="footer">
      <!-- 在这里放置底部的内容 -->
      <div class="box">
        <div class="copyRight">
          <div
            class="copyRightContent"
          >{{'版权所有 © 2021-2024 保留所有权利.技术支持：江苏鹤都云人工智能科技有限公司' | formatTrans}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { globalVari } from "@/utils/variable";
import { getshopSeries } from "@/api/index";
import { getTrans } from "@/api/set.js";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import shoppingBgImg from "@/assets/images/shoppingBg.png";
export default {
  name: "Home",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      hasDatas: "", //是否有数据
      shopId: globalVari.shopId, // 店铺id
      activeMenuIndex: 2, // 当前激活菜单的索引
      activeNames: ["1"],
      SelectedSeriesList: [],
      shoppingBgImg,
      rules: {}
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    // 获取精品系列
    fetchData() {
      let obj = {
        shopId: this.shopId
      };
      getshopSeries(obj).then(res => {
        if (res.code == 1 && res.data.length > 0) {
          this.SelectedSeriesList = res.data;
          this.hasDatas = false;
        } else {
          this.hasDatas = true;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });
    },

    // 精选系列详情
    goSelectedSeriesDetail(val) {
      this.$router.push({
        path: "/SelSerDescribe",
        query: { goodsIds: JSON.stringify(val.goodsIds), selSTitle: val.title }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.SelectedSeries {
  height: 100vh;
  .footer {
    background: #1c0a00;
    width: 100%;
    .box {
      width: 1200px;
      margin: 0 auto;
      // position: fixed;
      z-index: 99999;
      bottom: 0;
      height: 60px;
      background: #1c0a00;
      .copyRight {
        .copyRightContent {
          text-align: center;
          color: #c3bebc;
          font-size: 16px;
          line-height: 60px;
        }
      }
    }
  }
  .s-top-space-line {
    height: 80px;
  }

  .s-bot-space-line {
    height: 100px;
  }

  .hasDatas {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }

  ::v-deep .el-collapse-item__header {
    font-size: 26px;
    padding: 10px 0;
  }

  .f-row {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    margin: 0 auto;
    margin-top: 60px;

    .content-box {
      width: 588px;
      margin-top: 48px;
      margin-right: 24px;
      cursor: pointer;
    }

    .content-box:nth-child(1) {
      margin-top: 0;
    }

    .content-box:nth-child(2) {
      margin-top: 0;
    }

    .content-box:nth-child(2n) {
      margin-right: 0;
    }

    .fcetBg {
      width: 100%;
      height: 360px;

      .img-seal {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }

    .fcet {
      text-align: center;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 24px;
    }
  }
}
</style>

