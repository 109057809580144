import { render, staticRenderFns } from "./ClassificationDescribe.vue?vue&type=template&id=9352d9a4&scoped=true"
import script from "./ClassificationDescribe.vue?vue&type=script&lang=js"
export * from "./ClassificationDescribe.vue?vue&type=script&lang=js"
import style0 from "./ClassificationDescribe.vue?vue&type=style&index=0&id=9352d9a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9352d9a4",
  null
  
)

export default component.exports