<template>
  <div class="footer">
    <!-- 在这里放置底部的内容 -->
    <div class="box">
      <div class="copyRight">
        <div
          class="copyRightContent"
        >{{'版权所有 © 2021-2024 保留所有权利.技术支持：江苏鹤都云人工智能科技有限公司' | formatTrans}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      isFooterVisible: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const {
        scrollTop,
        scrollHeight,
        clientHeight
      } = document.documentElement;
      this.isFooterVisible = scrollHeight - scrollTop <= clientHeight;
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  background: #1c0a00;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  .box {
    width: 1200px;
    margin: 0 auto;
    z-index: 99999;
    bottom: 0;
    height: 60px;
    background: #1c0a00;
    .copyRight {
      .copyRightContent {
        text-align: center;
        color: #c3bebc;
        font-size: 16px;
        line-height: 60px;
      }
    }
  }
}
</style>