import Vue from "vue";
import App from "./App.vue";
import VueI18n from "vue-i18n";
import ElementUI from "element-ui";
import { Message, Loading } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueResource from "vue-resource";
import { getCurrentTrans, getTrans } from "@/api/set.js";
import router from "./router/router.js"; // 导入你的路由器

Vue.use(ElementUI);
// 全局挂载 Message 组件
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading

Vue.use(VueI18n);
Vue.config.productionTip = false;
const i18n = new VueI18n({
  locale: sessionStorage.getItem("pcLanguage") || "cn", //当前语言标识
  messages: {
    en: require("./assets/lang/en.js"), //英文语言包
    cn: require("./assets/lang/zh.js") //中文繁体语言包
  }
});
Vue.prototype._i18n = i18n;

Vue.prototype.$i18nMsg = function() {
  // return i18n.messages[i18n.locale]
  return i18n.messages.cn;
};

Vue.use(VueResource);

function getNewLanguage() {
  getCurrentTrans().then(res => {
    sessionStorage.setItem("i18nLang", JSON.stringify(res));
  });
}
var pcLanguage = sessionStorage.getItem("pcLanguage") || "cn";
if (pcLanguage) {
  getNewLanguage();
}

Vue.filter("formatTrans", function(src) {
  return getTrans(src);
});
Vue.prototype.getTrans = function(src) {
  return getTrans(src);
};

new Vue({
  router, // 将router挂载到Vue实例上
  render: h => h(App)
}).$mount("#app");
