<template>
    <div class="ForPasEmail">
      <Header/>
      <div class="fpe-top-space-line"></div>
      <div class="fpe-wrap">
        <div class="titles">
          <div class="titleBig">Password</div>
        </div>
        <div class="line">
          <img src="@/assets/images/login/login.png">
        </div>
        <div class="box">
          <div class="box-in">
            <el-form :model="fpeForm" :rules="rules" ref="fpeForm" class="fpeForm" v-show="formType == 1">
              <el-form-item prop="userEmail">
                <div class="e-mail-wrap">
                    <div>{{'邮箱地址' | formatTrans}} *</div>
                    <div class="e-input-wrap">
                        <el-input v-model="fpeForm.userEmail" clearable placeholder="E-mail Address"></el-input>
                        <el-button round @click="getCode" class="code-btn" v-show="!codeHasSend" >{{'获取验证码' | formatTrans}}</el-button>
                        <el-button round class="code-count-btn" v-show="codeHasSend" disabled>{{'重新发送' | formatTrans}}（{{ codeCount }}s）</el-button>
                    </div>
                </div>
              </el-form-item>
              <el-form-item prop="verCode">
                {{'验证码' | formatTrans}} *
                <el-input v-model="fpeForm.verCode" clearable placeholder="Verification code"></el-input>
              </el-form-item>
              <el-form-item class="footerBtn">
                <el-button round @click="cancelSubmit" class="cancelbutton">Cancel</el-button>
                <el-button type="primary" round @click="submitFpeForm" class="button">{{'下一步' | formatTrans}}</el-button>
              </el-form-item>
            </el-form>
            <el-form :model="chanPasForm" :rules="cpsRules" ref="chanPasForm" class="chanPasForm" v-show="formType == 2">
              <el-form-item prop="newPassword">
                <div class="new-p-wrap">
                    {{'新密码' | formatTrans}} *
                    <el-input type="password" v-model="chanPasForm.newPassword" show-password clearable placeholder="New Password"></el-input>
                    <div class="password-tips">{{"请输入8个或更多字符以及至少一个数字，前导或尾随空格将被忽略。" | formatTrans}}</div>
                </div>
              </el-form-item>
              <el-form-item prop="cNewPassword">
                {{'确认密码' | formatTrans}} *
                <el-input type="password" v-model="chanPasForm.cNewPassword" show-password clearable placeholder="Confrm Password"></el-input>
              </el-form-item>
              <el-form-item class="footerBtn">
                <el-button round @click="cancelSubmit" class="cancelbutton">Cancel</el-button>
                <el-button type="primary" round @click="submitCpsForm" class="button">Sign in</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="fpe-bot-space-line"></div>
      <Footer/>
    </div>
  </template>
  <script>
  import Header from "@/views/layout/Header.vue";
  import Footer from "@/views/layout/Footer.vue";
  import { sendEmailCode, verifyEmailCode, emailResetPas } from "@/api/index";
  import { getTrans } from "@/api/set.js";
  import CryptoJS from "crypto-js";
  
  export default {
    name: "ForPasEmail",
    components: {
      Header,
      Footer
    },
    data() {
       var checkEmail = (rules, value, callback) => {
        const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
        if (!value) {
          return callback(new Error(getTrans("邮箱不能为空")));
        }
        setTimeout(() => {
          if (mailReg.test(value)) {
            callback();
          } else {
            callback(new Error(getTrans("邮箱账号格式不正确")));
          }
        }, 100);
      }

      var validatePassword = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(getTrans("请输入密码")));
        }
        if (!/^(?=.*\d).{8,}$/.test(value)) {
          callback(new Error(getTrans("密码至少包含8个字符且必须包含一个数字")));
        } else {
          callback();
        }
      }

      var confirmPassword = (rule, value, callback) => {
        if (value === "") {
          callback(new Error(getTrans("请再次输入你的密码")));
        } else if (value !== this.chanPasForm.newPassword) {
          callback(new Error(getTrans("确认密码与密码不一致")));
        } else {
          callback();
        }
      }

      return {
        tabs: ["Login", "Create Account"], // 可以根据需要添加更多的标签
        activeIndex: 0, // 当前激活的Tab索引
        formType: 1,
        flagType: "password",
        fpeForm: {
            userEmail: "",
            verCode: "",
        },
        chanPasForm: {
            newPassword: "",
            cNewPassword: ""
        },
        sendMsgTimer: "",
        codeCount: 0,
        codeHasSend: false,
        rules: {
          userEmail: [
            {
              required: true,
              trigger: "blur",
              validator: checkEmail
            }
          ],
          verCode: [
            {
              required: true,
              message: "Verification code is required",
              trigger: "blur"
            }
          ]
        },
        cpsRules: {
            newPassword: [
                {
                    required: true,
                    trigger: "blur",
                    validator: validatePassword
                }
            ],
            cNewPassword: [
                {
                    required: true,
                    trigger: "blur",
                    validator: confirmPassword
                }
            ]
        },
      };
    },

    beforeDestroy() {
      clearInterval(this.sendMsgTimer)
      this.sendMsgTimer = ""
    },

    deactivated() {
      clearInterval(this.sendMsgTimer)
      this.sendMsgTimer = ""
    },

    methods: {
      getCode() {
        if (this.fpeForm.userEmail) {
          let mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
          if (mailReg.test(this.fpeForm.userEmail)) {
            if (!this.sendMsgTimer) {
              let timeNum = 60
              this.codeCount = 60
              this.codeHasSend = true
              this.sendMsgTimer = setInterval(() => {
                if (this.codeCount > 0 && this.codeCount <= 60) {
                  timeNum--
                  this.codeCount = timeNum
                } else {
                  clearInterval(this.sendMsgTimer)
                  this.codeHasSend = false
                  this.sendMsgTimer = ""
                }
              }, 1000)

              let obj = {
                userEmail: this.fpeForm.userEmail
              };

              sendEmailCode(obj).then(res => {
                if (res.code == 1) {
                  this.$message({
                    message: getTrans("发送成功"),
                    type: "success",
                    duration: 3000,
                    customClass: "auto-mes-box"
                  })
                } else {
                  this.$message({
                    message: getTrans(res.message),
                    type: "error",
                    duration: 3000,
                    customClass: "auto-mes-box"
                  })
                }
              });
            }
          } else {
            this.$message({
              message: getTrans("邮箱账号格式不正确"),
              type: "error",
              duration: 3000,
              customClass: "auto-mes-box"
            })
          }
        } else {
          this.$message({
            message: getTrans("邮箱不能为空"),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          })
        }
      },

      cancelSubmit() {
        this.$router.push("/Login");
      },

      submitFpeForm() {
        let data = {
          email: this.fpeForm.userEmail,
          emailCode: this.fpeForm.verCode,
        }

        this.$refs["fpeForm"].validate(valid => {
            if (valid) {
                verifyEmailCode(data).then(response => {
                    if (response.code == 1) {
                        
                        this.$message({
                            message: getTrans("验证成功"),
                            type: "success",
                            duration: 1000,
                            customClass: "auto-mes-box"
                        })
                        
                        this.formType = 2
                    } else {
                        this.$message({
                            message: getTrans(response.message),
                            type: "error",
                            duration: 3000,
                            customClass: "auto-mes-box"
                        })
                    }
                });
            }
        })
      },

      submitCpsForm() {
        let data = {
            email: this.fpeForm.userEmail,
            password: CryptoJS.MD5(this.chanPasForm.newPassword).toString(),
        }

        this.$refs["chanPasForm"].validate(valid => {
            if (valid) {
                emailResetPas(data).then(response => {
                    if (response.code == 1) {
                        
                        this.$message({
                            message: getTrans("重置成功"),
                            type: "success",
                            duration: 1000,
                            customClass: "auto-mes-box"
                        })
                        
                        this.$router.push("/Login");
                    } else {
                        this.$message({
                            message: getTrans(response.message),
                            type: "error",
                            duration: 3000,
                            customClass: "auto-mes-box"
                        })
                    }
                });
            }
        })
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  .ForPasEmail {
  
    .fpe-top-space-line {
      height: 80px;
    }
  
    .fpe-bot-space-line {
      height: 100px;
    }
  
    .fpe-wrap {
      width: 1200px;
      margin: 0 auto;
      margin-top: 48px;
  
      .titles {
        text-align: center;
  
        .titleBig {
          font-size: 40px;
          color: #333;
          font-weight: 900;
        }
  
        .titleSmall {
          font-size: 14px;
          color: #666;
          margin: 10px 0;
        }
      }
  
      .line {
        width: 877px;
        margin: 0 auto;
        margin-top: 44px;
  
        img {
          width: 100%;
        }
      }
  
      .box {
        margin-top: 59px;
        padding: 50px 0;
        font-size: 16px !important;
        background: #f3f3f5;
  
        ::v-deep .el-form-item__content,
        ::v-deep .el-checkbox__label {
          font-size: 18px !important;
        }
  
        .box-in {
          width: 426px;
          margin: 0 auto;

          .e-input-wrap {
            display: flex;

            .code-btn {
                display: block;
                width: 115px;
                margin-left: 8px;
                color: #fff;
                background-color: #b98465;
                border-color: #b98465;
                padding: 0;
                flex-shrink: 0;
            }

            .code-count-btn {
                display: block;
                width: 115px;
                margin-left: 8px;
                padding: 0;
                flex-shrink: 0;
            }
          }

          .password-tips {
            margin-top: 5px;
            color: rgba(0, 0, 0, 0.55);
            font-size: 12px;
            line-height: 20px;
          }
        }
  
        .footerBtn {
          display: flex;
          justify-content: space-between;
  
          .cancelbutton {
            width: 205px;
            color: #2c3e50;
            background-color: #fff;
            border-color: #fff;
            font-size: 18px !important;
            margin-top: 20px;
          }

          .button {
            width: 205px;
            color: #fff;
            background-color: #b98465;
            border-color: #b98465;
            font-size: 18px !important;
            margin-top: 20px;
          }
        }
      }
    }
  }
  </style>
  
  