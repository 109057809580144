<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { getcurSymbol } from "@/api/index";

export default {
  name: "App",
  components: {},

  mounted() {
    this.getCSymbol()
  },

  activated() {
    this.getCSymbol()
  },

  methods: {
    // 获取货币
    getCSymbol() {
      let curSymbol = sessionStorage.getItem('currencySymbol')

      if (!curSymbol) {
        getcurSymbol().then(res => {
          if (res.code == 1) {
            sessionStorage.setItem('currencySymbol',res.data.currencySymbol)
          }
        })
      }
      
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  .hide {
    visibility: hidden;
  }
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
<style >
.auto-mes-box {
  z-index: 300000 !important;
}
</style>
