<template>
  <div class="SearchResult">
    <Header/>
    <div class="sear-top-space-line"></div>
    <div class="search-res-wrap">
      <div class="top-results">
        <span>"{{ searchVal | formatTrans }}"</span>
        {{ resultLen }} results
      </div>

      <div class="resultsNothing" v-show="resultLen <= 0">
        <p>
          <i class="el-icon-search"></i>
          {{'我们找不到任何东西' | formatTrans}}
          "{{ searchVal | formatTrans }}"
        </p>
        <div>{{'请仔细检查拼写，然后重试。' | formatTrans}}</div>
      </div>

      <div class="classifi-wrap" v-if="resultLen > 0">
        <div class="cla-el-wrap">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="Product" name="1">
              <template slot="title">
                <p class="titleS">{{ '产品' | formatTrans}}</p>
                <i class="fontT20" :class="judgeActive1('1')!=-1? 'el-icon-minus':'el-icon-plus'"></i>
              </template>
              <el-checkbox-group
                v-model="checkListPro"
                style="display: flex; flex-direction: column;"
                @change="producthandleChecked"
              >
                <el-checkbox
                  style="font-size:28px;"
                  :label="ele.shopGoodsCatId"
                  v-for="(ele,index1) in checkProducts"
                  :key="index1"
                >{{ ele.shopGoodsCatName }}</el-checkbox>
              </el-checkbox-group>
            </el-collapse-item>
            <el-collapse-item title="Price" name="2">
              <template slot="title">
                <p class="titleS">{{ '价格' | formatTrans}}</p>
                <i class="fontT20" :class="judgeActive2('2')!=-1? 'el-icon-minus':'el-icon-plus'"></i>
              </template>
              <el-radio-group
                v-model="checkListPrice"
                v-for="(ele,index2) in checkPrice"
                :key="index2"
                @change="pricehandleChecked(ele)"
                style="display: flex; flex-direction: column;"
              >
                <el-radio :label="ele.id" style="font-size:28px;">{{ ele.name }}</el-radio>
              </el-radio-group>
            </el-collapse-item>
          </el-collapse>
        </div>

        <div class="findproducts">
          <div class="findproductsImg" v-for="(ele,indexP) in shopGoodsList" :key="indexP">
            <div class="name">{{ele.commonName | formatTrans}}</div>
            <img class="commonImage" :src="ele.commonImage" alt="image" @click="goshopping(ele)">
            <div class="price">
              <div>{{curSymbol + ele.commonPrice }}</div>
              <img src="@/assets/images/shoppingBg.png" alt="image" @click="goshopping(ele)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sear-bot-space-line"></div>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import { listFilter, getShopCats } from "@/api/index";
import { getTrans } from "@/api/set.js";
import { globalVari } from "@/utils/variable";

export default {
  name: "SearchResult",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      resultLen: 0,
      searchVal: "",
      activeNames: ["1"],
      shopId: globalVari.shopId, // 固定
      checkListPro: [],
      checkProducts: [],
      checkListPrice: "",
      shopGoodsList: [],
      maxPrice: "", //最高价格
      minPrice: "", //最低价格
      curSymbol: ""
    };
  },

  computed: {
    checkPrice() {
      let curSymbol = sessionStorage.getItem("currencySymbol");
      return [
        {
          id: "0",
          name: curSymbol + "0-" + curSymbol + "99"
        },
        {
          id: "1",
          name: curSymbol + "100-" + curSymbol + "199"
        },
        {
          id: "2",
          name: curSymbol + "200-" + curSymbol + "299"
        },
        {
          id: "3",
          name: curSymbol + "300-" + curSymbol + "399"
        }
      ];
    }
  },

  watch: {
    // 监听路由对象中的$route属性
    $route: {
      handler: function(to, from) {
        this.curSymbol = sessionStorage.getItem("currencySymbol");
        this.searchVal = this.$route.query.searchForm;
        this.getResults();
      },
      immediate: true
    }
  },

  mounted() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
    this.searchVal = this.$route.query.searchForm;
    this.getResults();
  },

  methods: {
    getResults() {
      let obj = {
        shopId: this.shopId,
        shopCatId: "",
        parentId: 0,
        orderType: "",
        commonName: this.searchVal,
        priceOrder: "",
        saleNum: "",
        addTime: "",
        collectSum: "",
        page: 1,
        limit: 20,
        maxPrice: "", //最高价格
        minPrice: "" //最低价格
      };

      listFilter(obj)
        .then(res => {
          if (res.code == 1 && res.data) {
            this.shopGoodsList = res.data.page.list;
            this.resultLen = res.data.page.list.length;
          } else {
            this.shopGoodsList = [];
            this.resultLen = 0;

            this.$message({
              message: getTrans(response.message),
              type: "error",
              duration: 3000,
              customClass: "auto-mes-box"
            });
          }
        })
        .then(() => {
          if (this.shopGoodsList.length > 0) {
            this.fetchDatashopCats();
          }
        });
    },

    // 获取品牌店铺详情二类
    fetchDatashopCats() {
      let obj = {
        shopId: this.shopId
      };

      getShopCats(obj).then(res => {
        if (res.code == 1 && res.data) {
          this.checkProducts = res.data.shopSeltCatList;
        } else {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box"
          });
        }
      });
      this.$forceUpdate();
    },

    handleChange(val) {},

    //判断是否打开
    judgeActive1(data) {
      return this.activeNames.indexOf(data);
    },

    judgeActive2(data) {
      return this.activeNames.indexOf(data);
    },

    producthandleChecked(value) {
      let obj = {
        shopId: this.shopId,
        shopCatIds: value,
        parentId: 0,
        orderType: "",
        commonName: this.searchVal,
        priceOrder: "",
        saleNum: "",
        addTime: "",
        collectSum: "",
        page: 1,
        limit: 20,
        maxPrice: this.maxPrice, //最高价格
        minPrice: this.minPrice //最低价格
      };

      listFilter(obj).then(res => {
        if (res.code == 1 && res.data) {
          this.shopGoodsList = res.data.page.list;
        }
      });
    },

    pricehandleChecked(value) {
      if (value) {
        if (value.id == "0") {
          this.minPrice = 0;
          this.maxPrice = 99;
        } else if (value.id == "1") {
          this.minPrice = 100;
          this.maxPrice = 199;
        } else if (value.id == "2") {
          this.minPrice = 200;
          this.maxPrice = 299;
        } else if (value.id == "3") {
          this.minPrice = 300;
          this.maxPrice = 399;
        }
      } else {
        this.minPrice = "";
        this.maxPrice = "";
      }

      let obj = {
        shopId: this.shopId,
        shopCatIds: this.checkListPro,
        parentId: 0,
        orderType: "",
        commonName: this.searchVal,
        priceOrder: "",
        saleNum: "",
        addTime: "",
        collectSum: "",
        page: 1,
        limit: 20,
        maxPrice: this.maxPrice, //最高价格
        minPrice: this.minPrice //最低价格
      };

      listFilter(obj).then(res => {
        if (res.code == 1 && res.data) {
          this.shopGoodsList = res.data.page.list;
        }
      });

      this.pricecheckDatas = value;
    },

    goshopping(val) {
      this.$router.replace({
        path: "/productsDesc",
        query: {
          goodsId: val.goodsId
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.sear-top-space-line {
  height: 80px;
}

.sear-bot-space-line {
  height: 100px;
}

.SearchResult {
  .search-res-wrap {
    width: 1200px;
    margin: 0 auto;
    margin-top: 32px;
  }

  .top-results {
    span {
      font-size: 32px;
      font-weight: bold;
    }
  }

  .resultsNothing {
    text-align: center;
    margin-top: 190px;

    p {
      font-size: 30px;
      font-weight: bold;
      padding: 20px;
    }
  }

  .classifi-wrap {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 32px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);

    .cla-el-wrap {
      width: 240px;
      margin-right: 40px;

      .el-collapse {
        border-top: none;
      }

      .titleS {
        color: rgba(0, 0, 0, 0.85);
        font-size: 20px;
        padding: 10px 0;
      }
    }

    .findproducts {
      margin: 0 auto;
      width: 960px;
      display: flex;
      flex-wrap: wrap;

      .findproductsImg:hover {
        background: #f0f0f0;
        box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.35);
      }

      .findproductsImg {
        cursor: pointer;
        background: #f3f3f5;
        border-radius: 40px;
        width: 296px;
        height: 380px;
        display: flex;
        flex-direction: column;
        margin-right: 16px;

        .name {
          color: rgba(0, 0, 0, 0.85);
          padding: 0 36px;
          padding-top: 20px;
          font-size: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }

        .commonImage {
          width: 240px;
          margin: 0 auto;
          margin-top: 20px;
        }

        .price {
          color: rgba(127, 73, 42, 1);
          font-size: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 36px;
          margin-top: 16px;

          img {
            width: 36px;
            cursor: pointer;
          }
        }
      }

      .findproductsImg:nth-child(3n) {
        margin-right: 0;
      }

      .findproductsImg:nth-child(3n + 1),
      .findproductsImg:nth-child(3n + 2),
      .findproductsImg:nth-child(3n + 3) {
        margin-top: 16px;
      }
    }
  }

  ::v-deep .el-checkbox__inner::after {
    height: 7px;
    left: 5px;
    top: 3px;
    width: 3px;
  }

  ::v-deep .el-checkbox__inner {
    width: 18px;
    height: 18px;
  }

  ::v-deep .el-radio__inner::after {
    height: 7px;
    left: 5px;
    top: 3px;
    width: 3px;
    transform: rotate(45deg) scaleY(1);
    box-sizing: content-box;
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    transform-origin: center;
    border-radius: 0%;
    background-color: transparent;
  }

  ::v-deep .el-radio__inner {
    width: 18px;
    height: 18px;
    border-radius: 2px;
  }

  ::v-deep .el-radio__label {
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    margin: 8px 0;
    display: inline-block;
    padding-left: 10px;
    line-height: 19px;
  }

  ::v-deep .el-collapse-item__content {
    line-height: 1.77 !important;
  }

  ::v-deep .el-radio {
    line-height: 1.77 !important;
  }

  ::v-deep .el-collapse {
    .el-collapse-item__header {
      justify-content: space-between;
      .el-collapse-item__arrow {
        display: none;
      }
    }
  }

  ::v-deep .el-checkbox__label {
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    margin: 8px 0;
  }
}
</style>
