<template>
  <div class="regs-wrap">
    <Header/>
    <div class="regs-top-space-line">&nbsp;</div>
    <div class="createsuc-wrap">
      <div class="top-remind-fir">
        THANK YOU FOR REGISTERING WITH US. PLEASECHECKYOUR EMAIL TO ACTIVATE YOUR
        ACCOUNT
      </div>
      <div  class="top-remind-sec">
        We sent an email to {{userEmail}} with the instructions to activate
        your acc ount.
      </div>
      <div class="countdown">
        <span class="circle" v-if="countdown">{{ countdown }}</span>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import { emailActive } from "@/api/index";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
export default {
  name: "RegisterSuccess",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      rules: {},
      countdown: 3,
      timer: null,
      userEmail: this.$route.params.userEmail
    };
  },

  created() {
    this.startCountdown();
    this.emailActive();
  },

  methods: {
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.redirect();
        }
      }, 1000);
    },

    emailActive() {
      // 获取URL中的emailCode参数
      const emailCode = this.$route.query.emailCode;

      let data = { emailCode: emailCode };
      emailActive(data).then(res => {
        if (res.code == 1 && res.data) {
        } else {
          this.$message({
            message: res.message,
            type: "error"
          });
        }
      });
    },

    redirect() {
      this.$router.push("/MyAccount");
      clearInterval(this.timer);
    },

    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.regs-wrap {
  height: 100vh;

  ::v-deep .el-form-item__content,
  ::v-deep .el-checkbox__label {
    font-size: 18px !important;
  }

  .regs-top-space-line {
    height: 80px;
  }

  .createsuc-wrap {
    width: 1200px;
    margin: 0 auto;
    margin-top: 136px;

    .top-remind-fir {
      text-align: center; 
      font-size: 30px; 
    }

    .top-remind-sec {
      text-align: center;
      font-size: 20px;
      margin-top: 60px;
    }
  }
}

.countdown {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  .circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #d9d9d9;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
</style>
