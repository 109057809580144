<template>
  <div class="OrderStatus">
    <Header/>
    <el-row>
      <el-col :span="4" class>
        <div class="ToBeReceivedS">{{this.$route.query.detaildatas.ToBeReceived | formatTrans}}</div>
        <div
          class="ToBeReceivedS"
          v-show="this.$route.query.detaildatas.orderStatus == '1'"
        >{{'待付款' | formatTrans}}</div>
      </el-col>
      <el-col :span="16">
        <div class="clearb"></div>
        <div class="orderHistoryList">
          <p>{{'. Order Status' | formatTrans}}</p>
          <div class="orderStatusTitle">
            <div>
              <el-tag type="warning">{{'To Be Received' | formatTrans}}</el-tag>
            </div>
            <div
              class="havedays"
            >{{'You still have 3 days, 32 minutes, and 56 seconds left for automatic confrmation of receipt.' | formatTrans}}</div>
          </div>
        </div>
        <div class="orderHistoryList">
          <p>{{'. Logistics Information' | formatTrans}}</p>
          <div class="orderStatusTitle fldesc">
            <div>{{'Shipping Method: Express Delivery' | formatTrans}}</div>
            <div>{{'Logistics Company: Yunda Express' | formatTrans}}</div>
            <div>{{'Waybill Number:433968949735227' | formatTrans}}</div>
          </div>
        </div>
        <div class="orderHistoryList">
          <p>{{'. Order Information' | formatTrans}}</p>
          <div class="orderStatusTitle">
            <div class="ShippingAddress">{{'Shipping Address' | formatTrans}}</div>
            <div>{{'Zhang Linghe,214000,18361657527' | formatTrans}}</div>
            <div>{{'Waybill Numberdadfadfjsf sdgdjgsda;fasd;flk' | formatTrans}}</div>
          </div>
          <div class="orderStatusTitle">
            <div class="ShippingAddress">{{'Seller Information' | formatTrans}}</div>
            <div>
              <a @click="ContactUsFn">{{'Contact Us' | formatTrans}}</a>
            </div>
          </div>
        </div>
        <div class="orderHistoryList">
          <div class="orderStatusTitle">
            <div class="ShippingAddress">
              {{'Order Content' | formatTrans}}
              <div class>
                <div class="fl padR30">{{'Order Number:3200720240629180927' | formatTrans}}</div>
                <div class="fl padR30">{{'Order Time:2024-06-29 09:02:45' | formatTrans}}</div>
                <div class="fl">{{'Payment Time: 2024-06-29 09:03:58' | formatTrans}}</div>
              </div>
            </div>
            <div v-for="(item,index) in orderHistoryList" :key="index">
              <div
                class="hasOrderHistory"
                v-show="item.orderHistory.length <= 0 && item.secordCatName == 'ALL'"
              >
                <p>Your orders will appear here.</p>
                <p>Once you place an order, you will be able to follow its journey every step of the way.</p>
              </div>
              <div
                v-show="item.secordCatName == 'ALL' "
                v-for="(ele,index1) in item.orderHistory"
                :key="index1"
                class="borList"
              >
                <!-- <div class="clearb"></div> -->
                <!-- <div class="OrderNumber">
                  <div class="fl">Order Number:{{ele.OrderNumber}}</div>
                  <div class="fr">Order Time:{{ele.OrderTime}}</div>
                </div>-->
                <div class="clearb"></div>
                <el-row>
                  <el-col :span="18" class="borderLine">
                    <el-row>
                      <el-col :span="10" class="srcImg">
                        <img class="fl" src="@/assets/images/product07.png">
                      </el-col>
                      <el-col :span="14" class="contents">
                        <div class="fr">
                          <div class>{{ele.name}}</div>
                          <div class="fl">
                            <div class="fl">Colour:{{ele.name}}</div>
                            <div class="fl">Size:{{ele.name}}</div>
                            <div class="fl">Quantity: {{ele.name}}</div>
                          </div>
                          <div class>{{ele.Subtotal}}</div>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="6" class="contents">
                    <div class="fr">
                      <div>Subtotal:{{ele.Subtotal}}</div>
                      <br>
                      <div>Freight:{{ele.Subtotal}}</div>
                      <br>
                      <div>Tax:{{ele.Subtotal}}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="4" class="hide">3</el-col>
    </el-row>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
export default {
  name: "OrderStatus",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      orderHistoryList: [
        {
          secordCatId: 21,
          secordCatName: "ALL",
          ToBeReceived: "0",
          orderHistory: [
            {
              id: "0",
              OrderNumber: "3200720240629180927ALL",
              isDefault: "0",
              name: "Default Address",
              pename: "Zhang Linghe",
              address:
                "Liangxi District, Wuxi City,Jiangsu Province, China MainlandRoom 5602, Building A, Donglingxishang, Chongan Street",
              ZIP: "212400",
              Subtotal: "87.00",
              OrderTime: "2024-06-29",
              phone: "18361657527"
            },
            {
              id: "1",
              OrderNumber: "3200720240629180888ALL",
              isDefault: "1",
              name: "Default Address",
              pename: "Zhang Linghe",
              address:
                "Liangxi District, Wuxi City,Jiangsu Province, China MainlandRoom 5602, Building A, Donglingxishang, Chongan Street",
              ZIP: "212400",
              Subtotal: "87.00",
              OrderTime: "2024-06-29",
              phone: "18361657527"
            }
          ]
        },
        {
          secordCatId: 25,
          secordCatName: "Unpaid",
          ToBeReceived: "1",
          orderHistory: [
            {
              id: "0",
              OrderNumber: "3200720240629180927Unpaid",
              isDefault: "0",
              name: "Default Address",
              pename: "Zhang Linghe",
              address:
                "Liangxi District, Wuxi City,Jiangsu Province, China MainlandRoom 5602, Building A, Donglingxishang, Chongan Street",
              ZIP: "212400",
              Subtotal: "87.00",
              OrderTime: "2024-06-29",
              phone: "18361657527"
            },
            {
              id: "1",
              OrderNumber: "3200720240629180888Unpaid",
              isDefault: "1",
              name: "Default Address",
              pename: "Zhang Linghe",
              address:
                "Liangxi District, Wuxi City,Jiangsu Province, China MainlandRoom 5602, Building A, Donglingxishang, Chongan Street",
              ZIP: "212400",
              Subtotal: "87.00",
              OrderTime: "2024-06-29",
              phone: "18361657527"
            }
          ]
        },
        {
          secordCatId: 31,
          secordCatName: "Processing",
          catPic: "",
          orderHistory: []
        },
        {
          secordCatId: 21,
          secordCatName: "Shipped",
          catPic: "",
          orderHistory: []
        }
      ]
    };
  },
  methods: {
    ContactUsFn() {
      this.$router.push("/ContactUs");
    }
  }
};
</script>
<style lang="scss" scoped>
.OrderStatus {
  padding: 100px 0;
  overflow-y: auto;
  .ToBeReceivedS {
    font-size: 20px;
    color: #cb0a10;
    text-align: right;
    padding-right: 10px;
  }
  .orderHistoryList {
    p {
      font-size: 28px;
      color: #262626;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .orderStatusTitle {
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 10px;
      font-size: 18px;
      margin-bottom: 30px;
      .havedays {
        font-size: 16px;
        padding: 10px 2px;
      }
    }
    .fldesc {
      padding: 20px 0;
      justify-content: space-around;
      display: flex;
      align-items: center;
    }
    .ShippingAddress {
      font-weight: bold;
      padding: 10px 0;
      margin-bottom: 20px;
      div {
        font-weight: normal;
        padding: 10px 0;
      }
    }
  }
  .orderHistoryList {
    .hasOrderHistory {
      text-align: center;
      p:first-child {
        padding: 20px;
        font-size: 26px;
      }
      p:last-child {
        // padding: 20px;
        font-size: 16px;
      }
    }
    .borList {
      border-top: 1px #ccc solid;
      // padding: 20px;
      //   border-radius: 10px;
      //   margin: 20px 0;
      //   div:first-child {
      //     margin-top: 20px;
      //   }
      .srcImg {
        display: flex;
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中 */
        height: 200px; /* 容器高度 */
        img {
          width: 60%;
        }
      }
      .OrderNumber {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 30px;
        background: #ccc;
        padding: 10px 10px 0 10px;
      }
      .toBeReceived {
        font-size: 20px;
        color: #cb0a10;
      }
      .contents {
        display: flex;
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中 */
        height: 200px; /* 使容器高度为视口高度 */
        div {
          padding: 5px 0;
        }
      }
    }
    .cent {
      line-height: 50px;
      padding-left: 20px;
    }
    .OrderDetails {
      text-align: center;
      line-height: 50px;
      a {
        text-decoration: underline;
      }
    }
    // border: 1px #ccc solid;
    padding: 0 20px;
    margin: 20px 0;
    border-radius: 10px;
    p {
      font-size: 30px;
      font-weight: 550;
      padding-bottom: 10px;
    }
    div {
      // padding: 5px 0;
    }
    .operation {
      a {
        text-decoration: underline; /* 确保a标签有下划线 */
        padding: 0 20px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
</style>

