//语言包
//侧边菜单栏
export const sideBar = {
  "tradingCenter": '交易中心', //交易中心
  "enterprise": '企业管理', //企业管理
  "enterprises": '企业信息', //企业管理
  "order": '我的订单', //我的订单
  "views": '交易评价', //交易评价
  "shareRebate": "分享返利", //分享返利
  "myClient": "我的客户", //我的客户
  "focusCenter": '关注中心', //关注中心
  "myCollection": '我的收藏', //我的收藏
  "myTracks": '我的足迹', //我的足迹
  "Accountsecurity": '账户安全', //账户安全
  "memberCenter": '会员中心', //会员中心
  "memberInformation": '会员信息', //会员信息
  "receivingAddress": '收货地址', //收货地址
  "myMessage": '我的消息', //我的信息
  "assetCenter": "资产中心", //资产中心
  "myVoucher": "我的优惠券", //我的优惠券
  "myRedpacket": "我的红包", //我的红包
  "myPoints": "我的积分", //我的积分
  "serviceCenter": "服务中心", //服务中心
  "refundReturn": "退款及退货", //退款及退货
  "illegalReporting": '违规举报', //违规举报
  "mineInvoice": '我的发票', //我的发票
  "invoiceAdmin": '发票管理', //发票管理
}
//首页
export const tabBar = {
  "index": '首页', //首页i18n.tabBar.
}
//会员
export const member = {
  "MembershipLevel": '会员等级', //会员等级i18n.member.
  'MemberInformation': '会员信息', //会员信息
  'member1': '会员推荐与返利规则：', //会员推荐与返利规则：
  'member2': '通过分享注册链接或二维码，用户扫描二维码进入商城，成功注册后，自动成为用户下级！', //通过分享注册链接或二维码，用户扫描二维码进入商城，成功注册后，自动成为用户下级！
  'member3': '下级成功消费每一笔订单，用户可获得该笔订单的1.00%提成。', //下级成功消费每一笔订单，用户可获得该笔订单的1.00%提成。
  'member4': '1.推广链接', //1.推广链接
  'member5': '2.推广二维码' //2.推广二维码
}
//我的
export const personal = {
  'MyWallet': '我的钱包', //我的钱包i18n.personal.
  'TotalAssetsOfTheAccount': '账户总资产', //账户总资产
  'AccountBalance': '账户余额', //账户余额
  'CardBalance': '卡余额', //卡余额
  'RedEnvelopes': '红包', //红包
  'CashCoupon': '优惠券', //优惠券
  'Completed': '已完成', //已完成
  'Cancelled': '已取消', //已取消
  'MyShoppingCart': '我的购物车', //我的购物车
  'MyTracks': '我的足迹', //我的足迹
  'MyCollection': '我的收藏', //我的收藏
  'MyInvoice': '我的发票', //我的发票
  'InvoiceDetails': '发票详情', //发票详情
  'InvoiceInformation': '发票信息', //发票信息
  'OrderNumber': '订单号', //订单号
  'InvoiceType': '发票类型', //发票类型
  'ElectronicOrdinaryInvoice': '电子普通发票', //电子普通发票
  'InvoiceContent': '发票内容', //发票内容
  'InvoiceHeader': '发票抬头', //发票抬头
  'Personal': '个人', //个人
  'DownloadInvoice': '下载电子普通发票', //下载电子普通发票
  'MyMall': '我的商城', //我的商城
  'ReturnToHomePage': '返回商城首页', //返回商城首页
  'UserSettings': '用户设置', //用户设置
  'AccountSecurity': '账号安全', //账号安全
  'ModifyAvatar': '修改头像', //修改头像
  'ChangePassword': '修改密码', //修改密码
  'MyRedEnvelope': '我的红包', //我的红包
  'MyVoucher': '我的优惠券', //我的优惠券
  'myBalance': '我的钱包', //我的余额
  'OrdinaryInvoice': '普通发票', //普通发票
  'VATInvoice': '增值税发票', //增值税发票
  'BindNewPhone': '绑定新手机', //绑定新手机
  'MyNews': '我的消息', //我的消息
  'AnonymousEvaluation': '匿名评价', //匿名评价
  'Specifications': '规格', //规格
  'IWantToEvaluate': '我要评价', //我要评价
  'Refund': '退款', //退款
  'Return': '退款/退货', //退款/退货
  'MyPoints': '我的积分', //我的积分
  'PaymentMethod': '支付方式', //支付方式
  'PaymentMethod2': '支付方式:', //支付方式:
  'Unhandled': '未处理', //未处理
  'Processed': '已处理', //已处理
  'ProductPicture': '商品图片', //商品图片
  'State': '状态', //状态
  'Invoicerequired': '是否需要发票' ,//是否需要发票
  "invoiceApply": '发票申请', //发票申请
  "invoiceAddress": '发票地址管理', //发票地址管理
}
//订单
export const orders = {
  'Integral': '积分', //积分i18n.orders.
  'MyOrder': '我的订单', //我的订单
  'ViewAllOrders': '查看全部订单', //查看全部订单
  'PendingPayment': '待付款', //待付款
  'GoodsToBeReceived': '待收货', //待收货
  'Common': '共', //共
  'Total': '共计', //共计
  'Piece': '件', //件
  'EvaluationOrder': '评价订单', //评价订单
  'TransactionEvaluation': '交易评价', //交易评价
  'AllOrders': '全部订单', //全部订单
  'ToBeShipped': '待发货', //待发货
    'PartialDelivery': '部分发货', //部分发货
  'ToBeEvaluated': '待评价', //待评价
  'Consignee': '收货人', //收货人
  'OrderAmount': '订单金额', //订单金额
  'TransactionStatus': '交易状态', //交易状态
  'OrderDetails': '订单详情', //订单详情
  'PromotionInfo': '促销活动', //促销活动
  'ToBeWrittenOff': '待核销', //待核销
  'HadWrittenOff': '已核销', //已核销
  'OrderInformation': '订单信息', //订单信息
  'IncludingFreight': '含运费', //含运费
  'OrderNumber': '订单编号', //订单编号
  'OrderNumber2': '订单编号:', //订单编号:
  'TotalOrder': '订单总额', //订单总额
  'RefundManagement': '退款', //退款管理
  'BuyerRefundApplication': '买家退款申请', //买家退款申请
  'RefundsAndReturns': '退款及退货', //退款及退货
  'FillInLogisticsInformation': '填写物流信息', //填写物流信息
  'TransactionComplaints': '交易投诉', //交易投诉
  'RefundAmount': '退款金额', //退款金额
  'RefundAmount2': '退款金额:', //退款金额:
  'AuditStatus': '审核状态', //审核状态
  'RefundNumber': '退款编号', //退款编号
  'ReturnNumber2': '退货编号', //退货编号
  'NumberOfReturns': '退货件数', //退货件数
  'FillInLogistics': '填写物流', //填写物流
  'Remarks': '备注', //备注
  'CorporateName': '公司名称', //公司名称
  'TaxpayerRegistrationNumber': '纳税人登记号', //纳税人登记号
  'CompanyAddress': '公司地址', //公司地址
  'Telephone': '电话', //电话
  'BankOfDeposit': '开户银行', //开户银行
  'BankAccount': '银行账户', //银行账户
  'ControlOverInvoices': '发票管理', //发票管理
  'BillingInformation': '开票信息', //开票信息
  'InvoiceCode': '发票代码', //发票代码
  'InvoiceNumber': '发票号码', //发票号码
  'OrdinaryInvoice': '微信扫码获取电子普通发票', //微信扫码获取电子普通发票
  'ElectronicInvoice': '下载电子普通发票', //下载电子普通发票
  'AmountOfMoney': '金额', //金额
  'PaymentTime': '支付时间', //支付时间
  'OrderStatus': '订单状态', //订单状态
  'OrderStatus2': '订单状态：', //订单状态：
  'PlaceOrder': '提交订单', //提交订单
  'PaymentOrder': '支付订单', //支付订单
  'Evaluate': '评价', //评价
  'MerchantShipment': '商家发货', //商家发货
  'Invoice': '发票:', //发票:
  'BuyerMessage': '买家留言:', //买家留言:
  'Merchant2': '商　　家:', //商　　家:
  'Merchant3': '商家备注:', //商家备注:
  'RefundInstructions2': '退款说明:', //退款说明：
  'RefundInstructions3': '请输入退款说明' ,//请输入退款说明
  'Offer': '优惠活动',//优惠活动
}

// 按钮
export const btns = {
  'Delete': '删除', //删除i18n.btns.
  'AddToCart': '加入购物车', //加入购物车
  'Search': '搜索', //搜索
  'GoToTheShoppingCart': '去购物车', //去购物车
  'ModifyMobilePhone': '修改手机', //修改手机
  'SaveChanges': '保存修改', //保存修改
  'ClickUpload': '点击上传', //点击上传
  'UntieImmediately': '立即解绑', //立即解绑
  'BindNow': '立即绑定', //立即绑定
  'ConfirmSubmission': '确认提交', //确认提交
  'Preservation': '保存', //保存
  'NextStep': '下一步', //下一步
  'Binding': '绑 定', //绑 定
  'Edit': '编辑', //编辑
  'See': '服务详情', //服务详情
  'SelectPicture': '选取图片', //选取图片
  'Publish': '发  表', //发  表
  'GoToTheHomePage': '去首页看看', //去首页看看
  'Close': '关 闭', //关 闭
  'OrderPayment': '订单支付', //订单支付
  'CancellationOfOrder': '取消订单', //取消订单
  'DeleteOrder': '删除订单', //删除订单
  'AfterSale': '申请售后', //申请售后
  'ConfirmReceipt': '确认收货', //确认收货
  'DelayedReceipt': '延迟收货', //延迟收货
  'ApplyForRefund': '申请退款', //申请退款
  'Determine': '确 定', //确 定
  'Whole': '全部', //全部
  'ImmediatePayment': '立即支付', //立即支付
  'payproof': '支付凭证', //支付凭证
}
//商品
export const goods = {
  'CommodityCollection': '商品收藏', //商品收藏i18n.goods.
  'ViewAllItems': '查看所有商品', //查看所有商品
  'CommodityDetails': '商品明细', //商品明细
  'goods': '商品', //商品
  'ProductScore': '商品评分', //商品评分
  'TradeName': '商品名称', //商品名称
  'EvaluationContent': '评价内容', //评价内容
  'EvaluationTime': '评价时间', //评价时间
  'CommodityConsulting': '商品咨询', //商品咨询
  'NewlyAddedProducts': '最新加入的商品', //最新加入的商品
  'EvaluateCommodities': '评价商品', //评价商品
  'CommodityPrice': '商品价格', //商品价格
  'PhysicalOrders': '实物订单', //实物订单
  'VirtualOrder': '虚拟订单', //虚拟订单
  'PurchaseQuantity': '购买数量', //购买数量
  'SelectReturnedItems': '选择退货商品', //选择退货商品
  'RefundReason2': '退款原因:', //退款原因:
  'PleaseSelectRefundReason': '请选择退款原因', //请选择退款原因
  'TestingCommodities': '测试商品', //测试商品
  'RelatedGoods2': '相关商品', //相关商品
  'Reportingtime': '举报时间', //举报时间
  'StateProcessingResults': '状态处理结果' //状态处理结果

}
//查看更多
export const more = {
  'SeeMore': '查看更多', //查看更多i18n.more.
  'ContactInformation': '联系方式', //联系方式
  'Location': '所在地', //所在地
  'YES': '是', //是
  'NO': '否', //否
  'Year': '年', //年
  'Month': '月', //月
  'Day': '日', //日
  'SafetyLevel': '安全等级', //安全等级
  'UpdateTime': '更新时间', //更新时间
  'News': '消息', //消息
  'Reply': '回复', //回复
  'ReplyTime': '回复时间', //回复时间
  'OrderTime': '下单时间', //下单时间
  'OrderTime2': '下单时间:', //下单时间:
  'Operation': '操作', //操作
  'Total': '总额', //总额
  'Freight': '运费', //运费
  'FreeShipping': '免运费', //免运费
  'ExchangeCode': '兑换码', //兑换码
  'ViewWriteOffCode': '查看核销码', //查看核销码
  'CopeWith': '应付', //应付
  'PaymentTime': '付款时间', //付款时间
  'Total1': '总件数', //总件数
  'Location': '所在地区', //所在地区
  'PleaseSelect': '请选择', //请选择
  'Sender': '发信人', //发信人
  'Content': '内容', //内容
  'LastUpdate': '最后更新', //最后更新
  'Addressee': '收件人', //收件人
  'Title': '标题', //标题
  'ReleaseTime': '发布时间', //发布时间
  'SystemMessages': '系统消息', //系统消息
  'ReceivedMessage': '收到消息', //收到消息
  'SentMessage': '已发送消息', //已发送消息
  'SystemAnnouncement': '系统公告', //系统公告
  'AcceptSettings': '接受设置', //接受设置
  'PleaseEnterContent': '请输入内容', //请输入内容
  'SunPictures': '晒图片', //晒图片
  'ViewPicture': '查看图片', //查看图片
  'OriginalMerchandiseMap': '商品原图', //商品原图
  'StartDate': '开始日期', //开始日期
  'EndDate': '结束日期', //结束日期
  'SelectAll': '全选', //全选
  'PaymentAmount2': '实付金额:', //实付金额:
  'PaymentAmount3': '最大可退:', //最大可退:
  'Operation2': '请选择操作', //请选择操作
  'AddTime': '添加时间', //添加时间
  'Number': '数量' //数量
}

//提示词
export const tips = {
  'DistanceBecomesS': '距离成为', //距离成为i18n.tips.
  'StillNeed': '还需要', //还需要
  'CollectionContent': '您还没有收藏过任何内容哦！', //您还没有收藏过任何内容哦！
  'GoToHome': '去首页逛逛', //去首页逛逛
  'BrowseContent': '您还没有浏览过任何内容哦！', //您还没有浏览过任何内容哦！
  'ThisFunctionIsNotOpenYet': '该功能暂未开放', //该功能暂未开放
  'DirectlyIssuedByTheMerchant': '由商家直接开具', //由商家直接开具
  'MerchantSpecifiedInvoice': '订单中的商品由入驻商家销售，发票内容由商家决定（如需修改，请联系商家），发票由商家开具寄出。', //订单中的商品由入驻商家销售，发票内容由商家决定（如需修改，请联系商家），发票由商家开具寄出。
  'ContactCustomerService': '订单中的商品由入驻商家销售，如需开具增票，请联系商家在线客服咨询确认。', //订单中的商品由入驻商家销售，如需开具增票，请联系商家在线客服咨询确认。
  'Birthday': '填生日有惊喜哦', //填生日有惊喜哦
  'ImprovePersonalInformation': '完善个人信息资料，上传头像图片有助于您结识更多的朋友。', //完善个人信息资料，上传头像图片有助于您结识更多的朋友。
  'HeadPortrait': '头像默认尺寸为120x120像素，请根据系统操作提示进行裁剪并生效。', //头像默认尺寸为120x120像素，请根据系统操作提示进行裁剪并生效。
  'SelectPicture': '选择图片', //选择图片
  'YourAccountInformation': '您的账号信息', //您的账号信息
  'YourSecurityServices': '您的安全服务', //您的安全服务
  'AccountSecurity': '(建议您开启全部安全设置，以保障账户及资金安全)', //(建议您开启全部安全设置，以保障账户及资金安全)
  'RealNameAuthentication': '实名认证', //实名认证
  'ViewRealNameInformation': '查看实名信息', //查看实名信息
  'ViewRealNameInformation2': '实名认证', //实名认证
  'AfterVerification': '进行手机验证后，可用于接收敏感操作的身份验证信息，以及进行积分消费的验证确认，非常有助于保护您的账号和账户财产安全。', //进行手机验证后，可用于接收敏感操作的身份验证信息，以及进行积分消费的验证确认，非常有助于保护您的账号和账户财产安全。
  'EditShippingAddress': '编辑收货地址', //编辑收货地址
  'NoOrder': '最近没有下过订单哦~', //最近没有下过订单哦~
  'NoData': '暂无数据~', //暂无数据~
  'NoMember': '暂无会员~', //暂无会员~
  'NoCoupon': '暂无优惠券~', //暂无优惠券~
  'NoEvaluate': '还没有评价，快去评价吧~', //还没有评价，快去评价吧~
  'NoMessage': '还没有收到任何消息哦~', //还还没有收到任何消息哦~
  'NoRedpacket':'暂无红包~',//暂无红包
  'BuyOtherGoodsInstead': '改买其他商品', //改买其他商品
  'BuyerIsInitiative': '买家主动要求', //买家主动要求
  'TheAddressIsWrong': '地址写错了', //地址写错了
  'IDoNotWantToBuyIt': '不想买了', //不想买了
  'WriteOffMerchant': '请将该二维码出示给核销商家', //请将该二维码出示给核销商家
  'RelatedGoods': '相关商品交易信息', //相关商品交易信息
  'PleaseProductName': '请输入商品名称', //请输入商品名称
  'ReasonForCancelingTheOrder': '取消订单原因', //取消订单原因
  'SelectReturnQuantity': '选择退货数量', //选择退货数量
  'RegisteredMembers': '成功注册会员，增加', //成功注册会员，增加
  'ReturnIssue': '如订单发生退款、退货等问题时，退货/退款后相应积分扣除', //如订单发生退款、退货等问题时，退货/退款后相应积分扣除
  'GetIntegral': '购物并完成订单后获得订单总价x', //购物并完成订单后获得订单总价x
  'GetIntegral2': '倍数的积分', //倍数的积分
  'Integral2': '积分；', //积分；
  'SignIn': '会员每天登录，增加', //会员每天登录，增加
  'CreditCash': '合计（积分现金)', //合计（积分现金)
  'StateValue': '请选择状态值' //请选择状态值
}

// 店铺
export const shops = {
  'StoreCollection': '店铺收藏', //店铺收藏i18n.shops.
  'StoreScore': '店铺评分', //店铺评分
  'LogisticsService': '物流服务', //物流服务
  'ServiceAttitude': '服务态度', //服务态度
  'DescriptionMatch': '描述相符', //描述相符
  'ShopName': '店铺名称', //店铺名称
  'ShopLogo': '店铺Logo', //店铺Logo
  'ContactCustomerService': '联系客服', //联系客服
  'Business2': '商 家:', //商 家:
  'IntegralAcquisitionRule': '积分获得规则：', //积分获得规则：
  'Describe': '描述', //描述
  'IntegralChange': '积分收入/支出', //积分变更
  'Integral1': '积分总数：', //积分总数：
  'GiftInformation': '礼品信息', //礼品信息
  'CreditCash': '积分现金', //积分现金
  'ExchangeRecord': '兑换记录', //兑换记录
  'IntegralDetails': '积分明细', //积分明细
  'Denomination': '面额（元）', //面额（元）
  'TermOfValidity': '有效期', //有效期
  'ConditionsOfUse': '使用条件', //使用条件
  'OwningStore': '所属店铺', //所属店铺
  'VoucherName': '优惠券名称', //优惠券名称
  'Complaint1': '投诉商品', //投诉商品
  'Complaint2': '投诉主题', //投诉主题
  'Complaint3': '投诉时间', //投诉时间
  'Complaint4': '投诉状态', //投诉状态
  'Consultation1': '全部咨询', //全部咨询
  'Consultation2': '未回复咨询', //未回复咨询
  'Consultation3': '已回复咨询', //已回复咨询
}

//物流
export const logistics = {
  'AddReceivingAddress': '新增收货地址', //新增收货地址i18n.logistics.
  'ReceivingAddress': '收货地址', //收货地址
  'ReceivingAddress2': '收货地址:', //收货地址:
  'LogisticsInformation': '物流信息', //物流信息
  'ViewLogistics': '查看物流', //查看物流
  'LogisticsCompany': '物流公司', //物流公司
  'LogisticsCompany2': '物流公司：', //物流公司：
  'LogisticsCompany3': '请选择物流公司', //请选择物流公司
  'LogisticsOrderNo': '物流单号', //物流单号
  'LogisticsOrderNo2': '物流单号:', //物流单号:
  'ReturnsManagement': '退货管理', //退货管理
  'BuyerReturnRequest': '买家退货申请', //买家退货申请
  'ProcessingResults': '处理结果', //处理结果
  'SelectAddress': '请选择收货地址', //请选择收货地址
  'StreetAddress': '街道地址', //街道地址
  'DefaultAddress': '默认地址', //默认地址
  'ReturnGoods': '退货', //退货
  'ReturnReason2': '退货原因:', //退货原因:
  'ReturnReason3': '请选择退货原因', //请选择退货原因
  'ReturnQuantity': '退货数量', //退货数量
  'ReturnQuantity2': '退货数量:', //退货数量:
  'ReturnQuantity3': '请输入退货数量', //请输入退货数量
  'ReturnPolicy2': '退货说明:', //退货说明:
  'ReturnPolicy3': '请输入退货说明', //请输入退货说明
  'LogisticsNumber2': '请输入物流单号', //请输入物流单号
  'ContactNumber': '联系号码:', //联系号码:
  'ContactNumber2': '请输入联系号码', //请输入联系号码
  'ReturnRequest': '退货申请', //退货申请
  'RefundApplication': '退款申请', //退款申请
  'Refund2': '买家申请退款', //买家申请退款
  'Refund3': '商家处理退款申请', //商家处理退款申请
  'Refund4': '申请人（买家）：', //申请人（买家）：
  'Refund5': '商家处理状态：', //商家处理状态：
  'Refund6': '商家备注：', //商家备注：
  'Refund8': '买家申请退货', //买家申请退货
  'Refund9': '商家处理退货申请', //商家处理退货申请
  'Refund11': '买家已填写物流', //买家已填写物流
  'Refund12': '商家确认收货(退款)', //商家确认收货(退款)
  'Refund7': '退货金额', //退货金额
}
//登录
export const SignIn = {
  'Man': '男', //男i18n.SignIn.
  'WoMan': '女', //女
  'Secrecy': '保密', //保密
  'Bound': '已绑定', //已绑定
  'UnBound': '未绑定', //未绑定
  'LoginAccount': '登陆账号', //登陆账号
  'PhoneNumber': '手机号码', //手机号码
  'LastLogin': '上次登录', //上次登录
  'MobilePhoneBinding': '手机绑定', //手机绑定
  'AccountBinding': '账号绑定', //账号绑定
  'Loginpassword': '登录密码', //登录密码
  'Paymentpassword': '支付密码', //支付密码
  'VerifyTheOriginalPhone': '验证原手机', //验证原手机
  'NickName': '昵称', //昵称
  'Gender': '性别', //性别
  'CellPhoneNumber': '手机号', //手机号
  'RealName': '真实姓名', //真实姓名
  'Birthday': '生日', //生日
  'AvatarPreview': '头像预览', //头像预览
  'HeadPortrait': '头像', //头像
  'ChangeTheAvatar': '更换头像', //更换头像
  'UserPortrait': '用户头像', //用户头像
  'MobilePhone1': '手机验证码', //手机验证码
  'MobilePhone2': '请输入手机验证码', //请输入手机验证码
  'SetPassword': '设置密码', //设置密码
  'SetPassword2': '再次设置密码', //再次设置密码
  'InputPassword': '请输入密码', //请输入密码
  'VerificationCode': '验证码', //验证码
  'PhoneNumber2': '请输入手机号码', //请输入手机号码
  'EssentialInformation': '基本信息', //基本信息
  'PortraitPhoto': '头像照片', //头像照片
  'ModifyLoginPassword': '修改登陆密码', //修改登陆密码
  'BuyerIsPortrait': '买家用户头像', //买家用户头像
  'RegistrationTime': '注册时间', //注册时间
  'UserName': '用户名', //用户名
  'MyCustomer': '我的客户', //我的客户
  'RecommendedLinks': '推荐链接', //推荐链接
  'ReturnRecord': '返利记录', //返利记录
  'Accountdescription': '用户实名认证是为了证实用户网站身份信息的真实性，提升账号的安全性。' ,//用户实名认证是为了证实用户网站身份信息的真实性，提升账号的安全性。
  'Accountdescription1': '手机号码在平台多处地方使用，例如手机号码登录、找回密码等。' ,//手机号码在平台多处地方使用，例如手机号码登录、找回密码等。
  'Accountdescription2': '用于账号密码登录时使用的验证密码，密码难度设置复杂对账号安全性更有保障' ,//用于账号密码登录时使用的验证密码，密码难度设置复杂对账号安全性更有保障
  'Accountdescription3': '用于在平台使用余额支付时输入的支付密码' ,//用于在平台使用余额支付时输入的支付密码
  'User': '用户' //用户
}
//发票
export const invoice = {
  'invoiceAdd': '新建发票', //新增发票
  'invoiceAddressAdd': '新建发票地址', //新建发票地址
  'invoiceEdit': '编辑发票', //发票编辑,
  'AddInvoiceReceivingAddress':'新建收票人地址',//新建收票人地址
  'EditInvoiceReceivingAddress':'编辑收票人地址',//编辑收票人地址
  'userName':'收票人',//收票人
  'invoiceDetails':'发票详情',//发票详情
  "day": "天", //天
  "hour": "时", // 时
  "minute": "分钟 ", // 分钟
  "second": "秒 ", // 秒
}
