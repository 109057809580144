<template>
  <div class="BrowseBrand">
    <Header/>
    <div class="bro-top-space-line"></div>
    <div class="brow-br-wrap">
      <div class="titles">
        <div class="titleBig">{{'浏览品牌' | formatTrans}}</div>
      </div>
      <div class="line"></div>
      <div class="noDatas" v-show="!hasDatas">{{"我们找不到任何东西" | formatTrans}}</div>

      <div v-for="(ele,index) in BrowseBrandList" :key="index" class="bro-bra-wrap">
        <div class="content-fir">
          <div>{{ele.title }}</div>
          <div class="right-btn">
            <el-button
              @click="goProductsDesc(ele)"
              type="primary"
              round
              class="btn"
            >{{'全部购物' | formatTrans}}</el-button>
            <img :src="arrowDownImg" alt v-show="selBrVal[index] == 1" @click="selItem(0,index)">
            <img :src="arrowUpImg" alt v-show="selBrVal[index] == 0" @click="selItem(1,index)">
          </div>
        </div>
        <div class="content-sec" v-show="selBrVal[index] == 0">
          <div class="left">
            <img :src="ele.bgUrl[0]" alt>
          </div>
          <div class="right">
            <img :src="ele.bgUrl[1]" alt class="bb-smal-fir">
            <img :src="ele.bgUrl[2]" alt class="bb-smal-sec">
            <img :src="ele.bgUrl[3]" alt class="bb-smal-third">
          </div>
        </div>
      </div>
    </div>
    <div class="bro-top-space-line"></div>
    <div class="footer">
      <!-- 在这里放置底部的内容 -->
      <div class="box">
        <div class="copyRight">
          <div
            class="copyRightContent"
          >{{'版权所有 © 2021-2024 保留所有权利.技术支持：江苏鹤都云人工智能科技有限公司' | formatTrans}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { globalVari } from "@/utils/variable";
import { getshopBrand } from "@/api/index";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import arrowDownImg from "@/assets/images/arrow-down.png";
import arrowUpImg from "@/assets/images/arrow-up.png";
import { getTrans } from "@/api/set.js";

export default {
  name: "BrowseBrand",
  components: {
    Header,
    Footer
  },

  data() {
    return {
      hasDatas: false, //是否有数据
      shopId: globalVari.shopId, // 店铺id
      BrowseBrandList: [],
      arrowDownImg,
      arrowUpImg,
      selBrVal: []
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    // 获取品牌店铺
    fetchData() {
      let obj = {
        shopId: this.shopId
      };

      getshopBrand(obj).then(res => {
        if (res.code == 1 && res.data) {
          this.hasDatas = true;
          this.BrowseBrandList = res.data;
          for (let i = 0; i < res.data.length; i++) {
            this.selBrVal.push(1);
          }

          this.$set(this.selBrVal, 0, 0);
        } else {
          this.hasDatas = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });
    },

    goProductsDesc(val) {
      this.$router.push({
        path: "/BrandDescribe",
        query: { brandId: val.brandId, branTitle: val.title }
      });
    },

    selItem(val, index) {
      for (let i = 0; i < this.selBrVal.length; i++) {
        this.$set(this.selBrVal, index, val);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.BrowseBrand {
  height: 100vh;
  .footer {
    background: #1c0a00;
    width: 100%;
    .box {
      width: 1200px;
      margin: 0 auto;
      // position: fixed;
      z-index: 99999;
      bottom: 0;
      height: 60px;
      background: #1c0a00;
      .copyRight {
        .copyRightContent {
          text-align: center;
          color: #c3bebc;
          font-size: 16px;
          line-height: 60px;
        }
      }
    }
  }
  .bro-top-space-line {
    height: 80px;
  }

  .bro-top-space-line {
    height: 100px;
  }

  .brow-br-wrap {
    width: 1200px;
    margin: 0 auto;
    margin-top: 36px;
  }

  .bro-bra-wrap {
    border-bottom: 1px solid #ccc;
  }

  .content-fir {
    color: rgba(0, 0, 0, 0.85);
    font-size: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 36px 0;

    .right-btn {
      width: 208px;
      height: 48px;
      display: flex;
      align-items: center;

      .btn {
        width: 100%;
        height: 100%;
        margin-top: 0 !important;
        padding: 0;
        border-radius: 24px;
        font-size: 16px !important;
      }

      img {
        width: 27px;
        margin-left: 48px;
        cursor: pointer;
      }
    }
  }

  .content-sec {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;

    .left {
      width: 594px;
      height: 382px;
      border-radius: 5px;

      img {
        width: 100%;
      }
    }

    .right {
      position: relative;
      width: 560px;
      height: 382px;

      .bb-smal-fir {
        position: absolute;
        left: 0;
        bottom: 46px;
        width: 238px;
        height: 210px;
        border-radius: 5px;
        z-index: 1000;
      }

      .bb-smal-sec {
        position: absolute;
        left: 173px;
        top: 45px;
        width: 180px;
        height: 182px;
        border-radius: 5px;
        z-index: 999;
      }

      .bb-smal-third {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 252px;
        height: 258px;
        border-radius: 5px;
        z-index: 998;
      }
    }
  }

  .titles {
    .titleBig {
      font-size: 40px;
      color: #333;
      font-weight: 900;
    }
  }

  .line {
    margin-top: 20px;
    width: 100%;
    height: 1px;
    background: #999;
  }

  .noDatas {
    text-align: center;
    margin-top: 59px;
    font-size: 20px;
    font-weight: 550;
  }

  ::v-deep .el-collapse-item__header {
    font-size: 26px;
    padding: 10px 0;
  }
}
</style>

