<template>
  <div class="resetPassword">
    <Header/>
    <el-row>
      <el-col :span="4">1</el-col>
      <el-col :span="16">
        <div class="titles">
          <div class="titleBig">Password</div>
        </div>
        <div class="line">
          <img src="@/assets/images/login/login.png" style="width: 100%; ">
        </div>
        <div class="box">
          <el-row>
            <el-col :span="6" class="hide">1</el-col>
            <el-col :span="12">
              <el-form :model="loginForm" :rules="rules" ref="loginForm" class="loginForm">
                <el-form-item prop="password">
                  New Password
                  <el-input :type="flagType" v-model="loginForm.password" clearable placeholder></el-input>
                  <div
                    class="passwordTips"
                  >Please enter 8 or more characters and at least one number. Leading ortrailingspaces will be ignored,</div>
                </el-form-item>
                <el-form-item prop="password">
                  confirm password
                  <el-input :type="flagType" v-model="loginForm.password" clearable placeholder>
                    <!-- <svg-icon
                          slot="prefix"
                          icon-class="password"
                          class="el-input__icon input-icon"
                        />
                        <svg-icon
                          slot="suffix"
                          :icon-class="this.flag?'eye-open':'eye'"
                          style="margin-top:8px;font-size:18px;"
                          @click="getFlag()"
                    />-->
                  </el-input>
                </el-form-item>
                <el-form-item class="footerBtn" style>
                  <el-row>
                    <el-col :span="11">
                      <el-button round @click="submitForm()" class="cancelbutton">Cancel</el-button>
                    </el-col>
                    <el-col :span="2" class="hide">1</el-col>
                    <el-col :span="11" class="txtR">
                      <el-button type="primary" round @click="submitForm()" class="button">Sign in</el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-form>
              <!-- <div class="clearb"></div> -->
            </el-col>
            <el-col :span="6" class="hide">1</el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="4" class="hide">3</el-col>
    </el-row>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      tabs: ["Login", "Create Account"], // 可以根据需要添加更多的标签
      activeIndex: 0, // 当前激活的Tab索引
      //密码小眼睛切换状态
      flag: false,
      flagType: "password",
      loginForm: {
        email: "",
        password: "",
        rememberMe: ""
      },
      forgotdialogVisible: false,
      forgotdialogVisibleSend: false,
      forgotForm: {},
      rules: {
        EmailAddress: [
          {
            required: true,
            message: "EmailAddress is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 忘记密码
    forgetPassWord() {
      this.forgotdialogVisible = true;
    },
    // 忘记密码-提交邮箱地址发送
    forgotSubmmit() {
      this.forgotdialogVisible = false;
      this.forgotdialogVisibleSend = true;
      //   xx().then(res => {
      //     if (res.succsee) {
      //       this.forgotdialogVisibleSend = true;
      //     } else {
      //       this.$modal.msgError(res.message);
      //     }
      //   });
    },

    //小眼睛切换状态
    getFlag() {
      this.flag = !this.flag;
      this.flagType = this.flag ? "text" : "password"; //text为显示密码；password为隐藏密码
    },
    // 注册成功跳转
    createsubmitForm() {
      this.$router.push("/RegisterSuccess");
    }
  }
};
</script>
<style lang="scss" scoped>
.resetPassword {
  .hide {
    visibility: hidden;
  }
  .clearb {
    clear: both;
  }
  padding-top: 100px;
  .titles {
    text-align: center;
    .titleBig {
      font-size: 40px;
      color: #333;
      font-weight: 900;
    }
    .titleSmall {
      font-size: 14px;
      color: #666;
      margin: 10px 0;
    }
  }
  .line {
    img {
      display: block;
      margin: 50px 0;
      //   padding: 0 60px;
    }
  }
  .box {
    // width: 50%;
    margin: 20px 0;
    padding: 50px 0;
    font-size: 16px !important;
    // text-align: center;
    background: #f3f3f5;
    ::v-deep .el-form-item__content,
    ::v-deep .el-checkbox__label {
      font-size: 18px !important;
    }
    .footerBtn {
      .cancelbutton {
        width: 100%;
        color: #2c3e50;
        background-color: #fff;
        border-color: #fff;
        font-size: 18px !important;
        margin-top: 20px;
      }
      .button {
        width: 100%;
        color: #fff;
        background-color: #b98465;
        border-color: #b98465;
        font-size: 18px !important;
        margin-top: 20px;
      }
    }

    //   border: 1px solid #ddd;
  }
  .passwordTips {
    padding-top: 0;
    font-size: 14px;
    color: #868687;
  }
}
</style>

