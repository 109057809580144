<template>
  <div class="AllClassification">
    <Header/>
    <el-row>
      <el-col :span="4" class="hide">1</el-col>
      <el-col :span="16">
        <div class="category" v-for="category in this.$route.query.mainCatDatas" :key="category.id">
          <div class="clearb"></div>
          <h3>{{ category.shopGoodsCatName | formatTrans }}</h3>
          <ul>
            <li
              @click="goProductsDesc(category.shopGoodsCatName)"
              v-for="subCategory in category.children"
              :key="subCategory.shopGoodsCatId"
            >
              <img :src="subCategory.shopGoodsCatImg">
              <span>{{ subCategory.shopGoodsCatName | formatTrans}}</span>
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :span="4" class="hide">3</el-col>
    </el-row>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import shoppingBgImg from "@/assets/images/shoppingBg.png";
import product01Img from "@/assets/images/product01.png";
import product02Img from "@/assets/images/product02.png";
export default {
  name: "AllClassification",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      hoverIndex: "",
      showhoverBg: false,
      shoppingBgImg,
      product01Img,
      product02Img,
      categories: [
        {
          id: 1,
          name: "Sofa & Stool",
          subCategories: [
            { id: 11, name: "Genuine leather sofa", src: product01Img },
            { id: 12, name: "Fabric sofa", src: product02Img }
            // ...更多子分类
          ]
        },
        {
          id: 2,
          name: "Table & Desk",
          subCategories: [
            { id: 21, name: "Stool", src: product01Img },
            { id: 32, name: "Tables", src: product01Img },
            { id: 42, name: "Tables", src: product02Img },
            { id: 52, name: "Tables", src: product01Img }
            // ...更多子分类
          ]
        }
        //...更多分类
      ]
    };
  },
  created() {
   
  },
  methods: {
    goProductsDesc(val) {
      this.$router.push({
        path: "/ClassificationDescribe",
        query: {
          val
        }
      });
    },
    // 立即购买
    shopnow(val) {
      this.val = val;
    },
    goshopping(val) {
      this.val = val;
    },
    Remove(val) {
      this.val = val;
    }
  }
};
</script>
<style lang="scss" scoped>
.AllClassification {
  padding: 100px 0;

  .hide {
    visibility: hidden;
  }
  .clearb {
    clear: both;
  }

  .category {
    text-align: left;
    padding-left: 20px;
    h3 {
      padding: 10px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }
    ul li {
      cursor: pointer;
      float: left;
      width: 22%;
      background: #f3f3f5;
      border-radius: 12px;
      padding: 5px 20px;
      margin: 10px;
      span {
        width: 50%;
        line-height: 80px;
        float: left;
        padding-left: 20px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    img {
      width: 80px;
      float: left;
      height: 80px;
    }
    // h3:hover {
    //   color: #313131;
    // }
  }
}
</style>

