//语言包
//侧边菜单栏
export const sideBar = {
  "tradingCenter": 'Trading Center', //交易中心
  "enterprise": 'enterprise', //企业管理
  "enterprises": 'enterprises', //企业管理
  "order": 'order', //我的订单
  "views": 'views', //交易评价
  "shareRebate": "Share rebate", //分享返利
  "myClient": "My client", //我的客户
  "focusCenter": 'Focus center', //关注中心
  "myCollection": 'My collection', //我的收藏
  "myTracks": 'My Tracks', //我的足迹
  "Accountsecurity": 'Account security', //账户安全
  "memberCenter": 'Member Center', //会员中心
  "memberInformation": 'Information', //会员信息
  "receivingAddress": 'Receiving address', //收货地址
  "myMessage": 'My message', //我的消息
  "assetCenter": "Asset Center", //资产中心
  "myVoucher": "My voucher", //我的优惠券
  "myRedpacket": "My Redpacket", //我的红包
  "myPoints": "My points", //我的积分
  "serviceCenter": "service center", //服务中心
  "refundReturn": "Refund return", //退款及退货
  "illegalReporting": 'Illegal reporting', //违规举报
  "mineInvoice": 'MineInvoice', //我的发票
  "invoiceAdmin": 'InvoiceAdmin', //发票管理
}
//首页
export const tabBar = {
  "index": 'index', //首页i18n.tabBar.
}
//会员
export const member = {
  "MembershipLevel": 'MembershipLevel', //会员等级i18n.member.
  'MemberInformation': 'MemberInformation', //会员信息
  'member1': 'Membership Recommendation and Return Rules:', //会员推荐与返利规则：
  'member2': 'By sharing the registration link or QR code, the user scans the QR code and enters the store. After successful registration, the user automatically becomes a subordinate of the user!', //通过分享注册链接或二维码，用户扫描二维码进入商城，成功注册后，自动成为用户下级！
  'member3': 'Subordinates consume each order successfully and users get a 1.00% Commission on the order.', //下级成功消费每一笔订单，用户可获得该笔订单的1.00%提成。
  'member4': '1. Promote Links', //1.推广链接
  'member5': '2. Popularize 2-D codes' //2.推广二维码
}
//我的
export const personal = {
  'MyWallet': 'MyWallet', //我的钱包i18n.personal.
  'TotalAssetsOfTheAccount': 'TotalAssetsOfTheAccount', //账户总资产
  'AccountBalance': 'AccountBalance', //账户余额
  'CardBalance': 'CardBalance', //卡余额
  'RedEnvelopes': 'RedEnvelopes', //红包
  'CashCoupon': 'CashCoupon', //优惠券
  'Completed': 'Completed', //已完成
  'Cancelled': 'Cancelled', //已取消
  'MyShoppingCart': 'MyShoppingCart', //我的购物车
  'MyTracks': 'MyTracks', //我的足迹
  'MyCollection': 'MyCollection', //我的收藏
  'MyInvoice': 'MyInvoice', //我的发票
  'InvoiceDetails': 'InvoiceDetails', //发票详情
  'InvoiceInformation': 'InvoiceInformation', //发票信息
  'OrderNumber': 'OrderNumber', //订单号
  'InvoiceType': 'InvoiceType', //发票类型
  'ElectronicOrdinaryInvoice': 'ElectronicOrdinaryInvoice', //电子普通发票
  'InvoiceContent': 'InvoiceContent', //发票内容
  'InvoiceHeader': 'InvoiceHeader', //发票抬头
  'Personal': 'Personal', //个人
  'DownloadInvoice': 'Download electronic general invoice', //下载电子普通发票
  'MyMall': 'MyMall', //我的商城
  'ReturnToHomePage': 'ToHomepage', //返回商城首页
  'UserSettings': 'UserSettings', //用户设置
  'AccountSecurity': 'AccountSecurity', //账号安全
  'ModifyAvatar': 'ModifyAvatar', //修改头像
  'ChangePassword': 'ChangePassword', //修改密码
  'MyRedEnvelope': 'MyRedEnvelope', //我的红包
  'MyVoucher': 'MyVoucher', //我的优惠券
  'myBalance': 'myBalance', //我的余额
  'OrdinaryInvoice': 'OrdinaryInvoice', //普通发票
  'VATInvoice': 'VATInvoice', //增值税发票
  'BindNewPhone': 'BindNewPhone', //绑定新手机
  'MyNews': 'MyNews', //我的消息
  'AnonymousEvaluation': 'AnonymousEvaluation', //匿名评价
  'Specifications': 'Specifications', //规格
  'IWantToEvaluate': 'IWantToEvaluate', //我要评价
  'Refund': 'Refund', //退款
  'Return': 'Return', //退款/退货
  'MyPoints': 'MyPoints', //我的积分
  'PaymentMethod': 'PaymentMethod', //支付方式
  'PaymentMethod2': 'PaymentMethod:', //支付方式:
  'Unhandled': 'Unhandled', //未处理
  'Processed': 'Processed', //已处理
  'ProductPicture': 'ProductPicture', //商品图片
  'State': 'State', //状态
  'Invoicerequired': 'Invoicerequired' ,//是否需要发票
  "invoiceApply": '发票申请', //发票申请
  "invoiceAddress": '发票地址管理', //发票地址管理
}
//订单
export const orders = {
  'Integral': 'Integral', //积分i18n.orders.
  'MyOrder': 'MyOrder', //我的订单
  'ViewAllOrders': 'ViewAllOrders', //查看全部订单
  'PendingPayment': 'PendingPayment', //待付款
  'GoodsToBeReceived': 'GoodsToBeReceived', //待收货
  'Common': 'Common', //共
  'Total': 'Total', //共计
  'Piece': 'Piece', //件
  'EvaluationOrder': 'EvaluationOrder', //评价订单
  'TransactionEvaluation': 'TransactionEvaluation', //交易评价
  'AllOrders': 'AllOrders', //全部订单
  'ToBeShipped': 'ToBeShipped', //待发货
   'PartialDelivery': 'Partial Delivery', //部分发货
  'ToBeEvaluated': 'ToBeEvaluated', //待评价
  'Consignee': 'Consignee', //收货人
  'OrderAmount': 'OrderAmount', //订单金额
  'TransactionStatus': 'TransactionStatus', //交易状态
  'OrderDetails': 'OrderDetails', //订单详情
  'PromotionInfo': 'PromotionInfo', //促销活动
  'ToBeWrittenOff': 'ToBeWrittenOff', //待核销
  'HadWrittenOff': 'HadBeWrittenOff', //已核销
  'OrderInformation': 'OrderInformation', //订单信息
  'IncludingFreight': 'IncludingFreight', //含运费
  'OrderNumber': 'OrderNumber', //订单编号
  'OrderNumber2': 'OrderNumber:', //订单编号:
  'TotalOrder': 'TotalOrder', //订单总额
  'RefundManagement': 'RefundManagement', //退款管理
  'BuyerRefundApplication': 'BuyerRefundApplication', //买家退款申请
  'RefundsAndReturns': 'RefundsAndReturns', //退款及退货
  'FillInLogisticsInformation': 'FillInLogisticsInformation', //填写物流信息
  'TransactionComplaints': 'TransactionComplaints', //交易投诉
  'RefundAmount': 'RefundAmount', //退款金额
  'RefundAmount2': 'RefundAmount:', //退款金额:
  'AuditStatus': 'AuditStatus', //审核状态
  'RefundNumber': 'RefundNumber', //退款编号
  'ReturnNumber2': 'ReturnNumber:', //退货编号
  'NumberOfReturns': 'NumberOfReturns', //退货件数
  'FillInLogistics': 'FillInLogistics', //填写物流
  'Remarks': 'Remarks', //备注
  'CorporateName': 'CorporateName', //公司名称
  'TaxpayerRegistrationNumber': 'TaxpayerRegistrationNumber', //纳税人登记号
  'CompanyAddress': 'CompanyAddress', //公司地址
  'Telephone': 'Telephone', //电话
  'BankOfDeposit': 'BankOfDeposit', //开户银行
  'BankAccount': 'BankAccount', //银行账户
  'ControlOverInvoices': 'ControlOverInvoices', //发票管理
  'BillingInformation': 'BillingInformation', //开票信息
  'InvoiceCode': 'InvoiceCode', //发票代码
  'InvoiceNumber': 'InvoiceNumber', //发票号码
  'OrdinaryInvoice': 'Wechat scanning code to obtain electronic ordinary invoice', //微信扫码获取电子普通发票
  'ElectronicInvoice': 'Download electronic general invoice', //下载电子普通发票
  'AmountOfMoney': 'AmountOfMoney', //金额
  'PaymentTime': 'PaymentTime', //支付时间
  'OrderStatus': 'OrderStatus', //订单状态
  'OrderStatus2': 'OrderStatus:', //订单状态：
  'PlaceOrder': 'PlaceOrder', //提交订单
  'PaymentOrder': 'PaymentOrder', //支付订单
  'Evaluate': 'Evaluate', //评价
  'MerchantShipment': 'MerchantShipment', //商家发货
  'Invoice': 'Invoice:', //发票:
  'BuyerMessage': 'BuyerMessage:', //买家留言；
  'Merchant2': 'Merchant:', //商　　家:
  'Merchant3': 'Merchant:', //商家备注:
  'RefundInstructions2': 'RefundInstructions:', //退款说明：
  'RefundInstructions3': 'Please enter a refund description' ,//请输入退款说明
  'Offer': 'Offer:',//优惠活动
}

// 按钮
export const btns = {
  'Delete': 'Delete', //删除i18n.btns.
  'AddToCart': 'AddToCart', //加入购物车
  'Search': 'Search', //搜索
  'GoToTheShoppingCart': 'GoToTheShoppingCart', //去购物车
  'ModifyMobilePhone': 'ModifyMobilePhone', //修改手机
  'SaveChanges': 'SaveChanges', //保存修改
  'ClickUpload': 'ClickUpload', //点击上传
  'UntieImmediately': 'UntieImmediately', //立即解绑
  'BindNow': 'BindNow', //立即绑定
  'ConfirmSubmission': 'ConfirmSubmission', //确认提交
  'Preservation': 'Preservation', //保存
  'NextStep': 'NextStep', //下一步
  'Binding': 'Binding', //绑 定
  'Edit': 'Edit', //编辑
  'See': 'See', //服务详情
  'SelectPicture': 'SelectPicture', //选取图片
  'Publish': 'Publish', //发  表
  'GoToTheHomePage': 'GoToTheHomePage', //去首页看看
  'Close': 'Close', //关 闭
  'OrderPayment': 'OrderPayment', //订单支付
  'CancellationOfOrder': 'CancellationOfOrder', //取消订单
  'DeleteOrder': 'DeleteOrder', //删除订单
  'AfterSale': 'AfterSale', //申请售后
  'ConfirmReceipt': 'ConfirmReceipt', //确认收货
  'DelayedReceipt': 'DelayedReceipt', //延迟收货
  'ApplyForRefund': 'ApplyForRefund', //申请退款
  'Determine': 'Determine', //确 定
  'Whole': 'Whole', //全部
  'ImmediatePayment': 'ImmediatePayment', //立即支付
  'payproof': 'payproof', //支付凭证
}
//商品
export const goods = {
  'CommodityCollection': 'CommodityCollection', //商品收藏i18n.goods.
  'ViewAllItems': 'ViewAllItems', //查看所有商品
  'CommodityDetails': 'CommodityDetails', //商品明细
  'goods': 'goods', //商品
  'ProductScore': 'ProductScore', //商品评分
  'TradeName': 'TradeName', //商品名称
  'EvaluationContent': 'EvaluationContent', //评价内容
  'EvaluationTime': 'EvaluationTime', //评价时间
  'CommodityConsulting': 'CommodityConsulting', //商品咨询
  'NewlyAddedProducts': 'Newly added products', //最新加入的商品
  'EvaluateCommodities': 'EvaluateCommodities', //评价商品
  'CommodityPrice': 'CommodityPrice', //商品价格
  'PhysicalOrders': 'PhysicalOrders', //实物订单
  'VirtualOrder': 'VirtualOrder', //虚拟订单
  'PurchaseQuantity': 'PurchaseQuantity', //购买数量
  'SelectReturnedItems': 'SelectReturnedItems', //选择退货商品
  'RefundReason2': 'RefundReason:', //退款原因:
  'PleaseSelectRefundReason': 'PleaseSelectRefundReason', //请选择退款原因
  'TestingCommodities': 'TestingCommodities', //测试商品
  'RelatedGoods2': 'RelatedGoods', //相关商品
  'Reportingtime': 'Reportingtime', //举报时间
  'StateProcessingResults': 'StateProcessingResults' //状态处理结果

}
//查看更多
export const more = {
  'SeeMore': 'SeeMore', //查看更多i18n.more.
  'ContactInformation': 'Contact', //联系方式
  'Location': 'Location', //所在地
  'YES': 'YES', //是
  'NO': 'NO', //否
  'Year': 'Year', //年
  'Month': 'Month', //月
  'Day': 'Day', //日
  'SafetyLevel': 'SafetyLevel', //安全等级
  'UpdateTime': 'UpdateTime', //更新时间
  'News': 'News', //消息
  'Reply': 'Reply', //回复
  'ReplyTime': 'ReplyTime', //回复时间
  'OrderTime': 'OrderTime', //下单时间
  'OrderTime2': 'OrderTime:', //下单时间:
  'Operation': 'Operation', //操作
  'Total': 'Total', //总额
  'Freight': 'Freight', //运费
  'FreeShipping': 'FreeShipping', //免运费
  'ExchangeCode': 'ExchangeCode', //兑换码
  'ViewWriteOffCode': 'ViewWriteOffCode', //查看核销码
  'CopeWith': 'CopeWith', //应付
  'PaymentTime': 'PaymentTime', //付款时间
  'Total1': 'Total1', //总件数
  'Location': 'Location', //所在地区
  'PleaseSelect': 'PleaseSelect', //请选择
  'Sender': 'Sender', //发信人
  'Content': 'Content', //内容
  'LastUpdate': 'LastUpdate', //最后更新
  'Addressee': 'Addressee', //收件人
  'Title': 'Title', //标题
  'ReleaseTime': 'ReleaseTime', //发布时间
  'SystemMessages': 'SystemMessages', //系统消息
  'ReceivedMessage': 'ReceivedMessage', //收到消息
  'SentMessage': 'SentMessage', //已发送消息
  'SystemAnnouncement': 'SystemAnnouncement', //系统公告
  'AcceptSettings': 'AcceptSettings', //接受设置
  'PleaseEnterContent': 'PleaseEnterContent', //请输入内容
  'SunPictures': 'SunPictures', //晒图片
  'ViewPicture': 'ViewPicture', //查看图片
  'OriginalMerchandiseMap': 'OriginalMerchandiseMap', //商品原图
  'StartDate': 'StartDate', //开始日期
  'EndDate': 'EndDate', //结束日期
  'SelectAll': 'SelectAll', //全选
  'PaymentAmount2': 'PaymentAmount:', //实付金额:
  'PaymentAmount3': 'PaymentAmount:', //最大可退:
  'Operation2': 'Please select an action', //请选择操作
  'AddTime': 'AddTime', //添加时间
  'Number': 'Number' //数量
}

//提示词
export const tips = {
  'DistanceBecomesS': 'DistanceBecomes', //距离成为i18n.tips.
  'StillNeed': 'StillNeed', //还需要
  'CollectionContent': 'You have not collected any content yet!', //您还没有收藏过任何内容哦！
  'GoToHome': 'Go to the home page', //去首页逛逛
  'BrowseContent': 'You have not browsed any content yet!', //您还没有浏览过任何内容哦！
  'ThisFunctionIsNotOpenYet': 'ThisFunctionIsNotOpenYet', //该功能暂未开放
  'DirectlyIssuedByTheMerchant': 'DirectlyIssuedByTheMerchant', //由商家直接开具
  'MerchantSpecifiedInvoice': 'Merchant specified invoice', //订单中的商品由入驻商家销售，发票内容由商家决定（如需修改，请联系商家），发票由商家开具寄出。
  'ContactCustomerService': 'ContactCustomerService', //订单中的商品由入驻商家销售，如需开具增票，请联系商家在线客服咨询确认。
  'Birthday': 'There is a surprise on your birthday', //填生日有惊喜哦
  'ImprovePersonalInformation': 'ImprovePersonalInformation', //完善个人信息资料，上传头像图片有助于您结识更多的朋友。
  'HeadPortrait': 'HeadPortrait', //头像默认尺寸为120x120像素，请根据系统操作提示进行裁剪并生效。
  'SelectPicture': 'SelectPicture', //选择图片
  'YourAccountInformation': 'YourAccountInformation', //您的账号信息
  'YourSecurityServices': 'YourSecurityServices', //您的安全服务
  'AccountSecurity': 'AccountSecurity', //(建议您开启全部安全设置，以保障账户及资金安全)
  'RealNameAuthentication': 'RealNameAuthentication', //实名认证
  'ViewRealNameInformation': 'ViewRealNameInformation', //查看实名信息
  'ViewRealNameInformation2': 'ViewRealNameInformation2', //实名认证
  'AfterVerification': 'AfterVerification', //进行手机验证后，可用于接收敏感操作的身份验证信息，以及进行积分消费的验证确认，非常有助于保护您的账号和账户财产安全。
  'EditShippingAddress': 'EditShippingAddress', //编辑收货地址
  'NoOrder': 'I have not placed an order recently~', //最近没有下过订单哦~
  'NoData': 'NoData', //暂无数据~
  'NoMember': 'NoMember~', //暂无会员~
  'NoCoupon': 'NoCoupon~', //暂无优惠券~
  'NoEvaluate': 'No evaluation yet, go to evaluate~', //还没有评价，快去评价吧~
  'NoMessage': 'I haven not received any news yet~', //还还没有收到任何消息哦~
  'NoRedpacket':'暂无红包~',//暂无红包
  'BuyOtherGoodsInstead': 'BuyOtherGoodsInstead', //改买其他商品
  'BuyerIsInitiative': 'BuyerIsInitiative', //买家主动要求
  'TheAddressIsWrong': 'TheAddressIsWrong', //地址写错了
  'IDoNotWantToBuyIt': 'IDoNotWantToBuyIt', //不想买了
  'WriteOffMerchant': 'Please show the QR code to the verification merchant', //请将该二维码出示给核销商家
  'RelatedGoods': 'Related commodity trading information', //相关商品交易信息
  'PleaseProductName': 'Please enter the product name', //请输入商品名称
  'ReasonForCancelingTheOrder': 'ReasonForCancelingTheOrder', //取消订单原因
  'SelectReturnQuantity': 'SelectReturnQuantity', //选择退货数量
  'RegisteredMembers': 'Successfully registered members, increase', //成功注册会员，增加
  'ReturnIssue': 'In case of refund or return of the order, the corresponding credit deducted after the return/refund', //如订单发生退款、退货等问题时，退货/退款后相应积分扣除
  'GetIntegral': 'Credit for total order price X', //购物并完成订单后获得订单总价x倍数的积分
  'GetIntegral2': 'after purchase and completion of the order', //倍数的积分
  'Integral2': 'Integral:', //积分；
  'SignIn': 'Members log in daily, increase', //会员每天登录，增加
  'CreditCash': 'Total (cash points)', //合计（积分现金)
  'StateValue': 'Please select a status value' //请选择状态值
}

// 店铺
export const shops = {
  'StoreCollection': 'StoreCollection', //店铺收藏i18n.shops.
  'StoreScore': 'StoreScore', //店铺评分
  'LogisticsService': 'LogisticsService', //物流服务
  'ServiceAttitude': 'ServiceAttitude', //服务态度
  'DescriptionMatch': 'DescriptionMatch', //描述相符
  'ShopName': 'ShopName', //店铺名称
  'ShopLogo': 'ShopLogo', //店铺Logo
  'ContactCustomerService': 'ContactCustomerService', //联系客服
  'Business2': 'Business:', //商 家:
  'IntegralAcquisitionRule': 'IntegralAcquisitionRule:', //积分获得规则：
  'Describe': 'Describe', //描述
  'IntegralChange': 'IntegralChange', //积分变更
  'Integral1': 'Total number of points:', //积分总数：
  'GiftInformation': 'GiftInformation', //礼品信息
  'CreditCash': 'CreditCash', //积分现金
  'ExchangeRecord': 'ExchangeRecord', //兑换记录
  'IntegralDetails': 'IntegralDetails', //积分明细
  'Denomination': 'Denomination (Yuan)', //面额（元）
  'TermOfValidity': 'Term of validity', //有效期
  'ConditionsOfUse': 'ConditionsOfUse', //使用条件
  'OwningStore': 'OwningStore', //所属店铺
  'VoucherName': 'VoucherName', //优惠券名称
  'Complaint1': 'Complaint about commodities', //投诉商品
  'Complaint2': 'Complaint Subject', //投诉主题
  'Complaint3': 'Complaint Time', //投诉时间
  'Complaint4': 'Complaint Status', //投诉状态
  'Consultation1': 'All Consultations', //全部咨询
  'Consultation2': 'No response to consultation', //未回复咨询
  'Consultation3': 'Consultation Responded', //已回复咨询
}

//物流
export const logistics = {
  'AddReceivingAddress': 'AddReceivingAddress', //新增收货地址i18n.logistics.
  'ReceivingAddress': 'ReceivingAddress', //收货地址
  'ReceivingAddress2': 'ReceivingAddress：', //收货地址:
  'LogisticsInformation': 'LogisticsInformation', //物流信息
  'ViewLogistics': 'ViewLogistics', //查看物流
  'LogisticsCompany': 'LogisticsCompany', //物流公司
  'LogisticsCompany2': 'LogisticsCompany:', //物流公司：
  'LogisticsCompany3': 'Please select a logistics company', //请选择物流公司
  'LogisticsOrderNo': 'LogisticsOrderNo', //物流单号
  'LogisticsOrderNo2': 'LogisticsOrderNo:', //物流单号:
  'ReturnsManagement': 'ReturnsManagement', //退货管理
  'BuyerReturnRequest': 'BuyerReturnRequest', //买家退货申请
  'ProcessingResults': 'ProcessingResults', //处理结果
  'SelectAddress': 'Please select the harvest address', //请选择收货地址
  'StreetAddress': 'Street', //街道地址
  'DefaultAddress': 'DefaultAddress', //默认地址
  'ReturnGoods': 'ReturnGoods', //退货
  'ReturnReason2': 'ReturnReason:', //退货原因:
  'ReturnReason3': 'Please select the reason for your return', //请选择退货原因
  'ReturnQuantity': 'ReturnQuantity', //退货数量
  'ReturnQuantity2': 'ReturnQuantity:', //退货数量:
  'ReturnQuantity3': 'Please enter the quantity returned', //请输入退货数量
  'ReturnPolicy2': 'ReturnPolicy:', //退货说明:
  'ReturnPolicy3': 'Please enter return instructions', //请输入退货说明
  'LogisticsNumber2': 'Please enter Logistics Number', //请输入物流单号
  'ContactNumber': 'ContactNumber:', //联系号码:
  'ContactNumber2': 'Please enter a contact number', //请输入联系号码
  'ReturnRequest': 'ReturnRequest', //退货申请
  'RefundApplication': 'RefundApplication', //退款申请
  'Refund2': 'Buyer applies for refund', //买家申请退款
  'Refund3': 'Business Processing Refund Application', //商家处理退款申请
  'Refund4': 'Applicant (Buyer):', //申请人（买家）：
  'Refund5': 'Business Processing Status:', //商家处理状态：
  'Refund6': 'Commerce Notes:', //商家备注：
  'Refund8': 'Buyer applies for return', //买家申请退货
  'Refund9': 'Business Processing Return Request', //商家处理退货申请
  'Refund11': 'Buyer Filled Logistics', //买家已填写物流
  'Refund12': 'Merchant Confirmation Receipt (Refund)', //商家确认收货(退款)
  'Refund7': 'Return amount', //退货金额
}
//登录
export const SignIn = {
  'Man': 'Man', //男i18n.SignIn.
  'WoMan': 'WoMan', //女
  'Secrecy': 'Secrecy', //保密
  'Bound': 'Bound', //已绑定
  'UnBound': 'UnBound', //未绑定
  'LoginAccount': 'LoginAccount', //登陆账号
  'PhoneNumber': 'PhoneNumber', //手机号码
  'LastLogin': 'LastLogin', //上次登录
  'MobilePhoneBinding': 'MobilePhoneBinding', //手机绑定
  'AccountBinding': 'AccountBinding', //账号绑定
  'Loginpassword': 'Loginpassword', //登录密码
  'Paymentpassword': 'Paymentpassword', //支付密码
  'VerifyTheOriginalPhone': 'VerifyTheOriginalPhone', //验证原手机
  'NickName': 'NickName', //昵称
  'Gender': 'Gender', //性别
  'CellPhoneNumber': 'cell-phone number', //手机号
  'RealName': 'RealName', //真实姓名
  'Birthday': 'Birthday', //生日
  'AvatarPreview': 'AvatarPreview', //头像预览
  'HeadPortrait': 'HeadPortrait', //头像
  'ChangeTheAvatar': 'ChangeTheAvatar', //更换头像
  'UserPortrait': 'UserPortrait', //用户头像
  'MobilePhone1': 'Mobile Phone Authentication Code', //手机验证码
  'MobilePhone2': 'Please enter your mobile phone verification code', //请输入手机验证码
  'SetPassword': 'SetPassword', //设置登录密码
  'SetPassword2': 'SetPassword2', //重复登录密码
  'InputPassword': 'InputPassword', //请输入登录密码
  'InputPassword2': 'InputPassword2', //请再次输入登录密码
  'VerificationCode': 'VerificationCode', //验证码
  'PhoneNumber2': 'Please enter your mobile number', //请输入手机号码
  'EssentialInformation': 'EssentialInformation', //基本信息
  'PortraitPhoto': 'PortraitPhoto', //头像照片
  'ModifyLoginPassword': 'ModifyLoginPassword', //修改登陆密码
  'BuyerIsPortrait': 'BuyerIsPortrait', //买家用户头像
  'RegistrationTime': 'RegistrationTime', //注册时间
  'UserName': 'UserName', //用户名
  'MyCustomer': 'MyCustomer', //我的客户
  'RecommendedLinks': 'RecommendedLinks', //推荐链接
  'ReturnRecord': 'ReturnRecord', //返利记录
  'Accountdescription': 'Accountdescription' ,//用户实名认证是为了证实用户网站身份信息的真实性，提升账号的安全性。
  'Accountdescription1': 'Accountdescription' ,//手机号码在平台多处地方使用，例如手机号码登录、找回密码等。
  'Accountdescription2': 'Accountdescription' ,//用于账号密码登录时使用的验证密码，密码难度设置复杂对账号安全性更有保障
  'Accountdescription3': 'Accountdescription' ,//用于在平台使用余额支付时输入的支付密码
  'User': 'User' ,//用户
}
//发票
export const invoice = {
  'invoiceAdd': 'invoiceAdd', //新增发票
  'invoiceAddressAdd': 'invoiceAddressAdd', //新建发票地址
  'invoiceEdit': 'invoiceEdit', //发票编辑
  'AddInvoiceReceivingAddress':'AddInvoiceReceivingAddress',//新建发票收货地址
  'EditInvoiceReceivingAddress':'EditInvoiceReceivingAddress',//编辑发票收货地址
  'userName':'userName',//收票人
  'invoiceDetails':'invoiceDetails',//发票详情
  "day": "day", //天
  "hour": "hour", // 时
  "minute": "Minute ", // 分钟
  "second": "second ", // 秒
}
