<template>
  <div class="main">
    <!-- 在这里放置主要内容区域的内容 -->
    <div class="banner">
      <el-carousel height="625px" direction="vertical" indicator-position="block">
        <el-carousel-item v-for="(item, index) in bannerImgList" :key="index">
          <img :src="item.url" alt="image" style="width: 100%; height: 100%">
          <!-- <h3 class="medium">
            <div class="shopnow" @click="shopnow">
              {{'shop now' | formatTrans}}
              <img
                width="27"
                height="20"
                src="@/assets/images/shopnow.png"
              >
            </div>
          </h3>-->
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="sec-section">
      <div class="disCountVal">-{{ discountPercent }}%</div>
    </div>

    <div class="third-section">
      <el-carousel height="420px" :interval="5000" arrow="always" indicator-position="none">
        <el-carousel-item>
          <div class="ths-products">
            <div
              class="ths-products-img"
              v-for="(item,index) in proList"
              :key="index"
              @click="goProductsDesc(item.goodsId)"
            >
              <img :src="item.commonImage" alt="image" class="t-item-img">
              <div class="ths-products-name">{{item.commonName }}</div>
              <div class="ths-products-price">
                <div class="tpp-val">{{ curSymbol + item.commonPrice }}</div>
                <img :src="shoppingBgImg" alt="image" class="shop-img-icon" @click="goshopping">
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="forth-section">
      <div class="forth-space">&nbsp;</div>
      <el-carousel height="250px" :interval="5000" arrow="always" indicator-position="none">
        <el-carousel-item>
          <div class="for-products">
            <div
              class="for-products-img"
              v-for="(ele,index) in newProList"
              :key="index"
              @click="goProductsDesc(ele.goodsId)"
            >
              <img :src="ele.commonImage" alt="image" class="f-item-img">
              <div class="for-products-desc">{{ele.commonName }}</div>
              <div class="for-products-price">
                <div class="fpp-val">{{ curSymbol + ele.commonPrice }}</div>
                <img :src="shoppingBgImg" alt="image" class="shop-img-icon" @click="goshopping">
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="fif-section">
      <div class="merchant">
        <div
          v-for="(ele,index) in merchantLists"
          :key="index"
          @click="skipBrand(ele.brandId,ele.brandTitle)"
        >
          <img :src="ele.imgUrl">
        </div>
      </div>
    </div>

    <div class="six-section">
      <div class="mostVieweproducts" @click="goProductsDesc(MostViewedImgsArr1.goodsId)">
        <div class="name">{{MostViewedImgsArr1.commonName}}</div>
        <img :src="MostViewedImgsArr1.commonImage" alt="image" class="goods-img">
        <div class="price">
          <div>{{ curSymbol + MostViewedImgsArr1.commonPrice }}</div>
          <img :src="shoppingBgImg" alt="image" @click="goshopping">
        </div>
      </div>
      <div class="mostVieweproductsTwo" @click="goProductsDesc(MostViewedImgsArr2.goodsId)">
        <div class="name">{{MostViewedImgsArr2.commonName }}</div>
        <img :src="MostViewedImgsArr2.commonImage" alt="image" class="goods-img">
        <div class="price">
          <div>{{ curSymbol + MostViewedImgsArr2.commonPrice }}</div>
          <img :src="shoppingBgImg" alt="image" @click="goshopping">
        </div>
      </div>
      <div class="mostVieweproductsThree" @click="goProductsDesc(MostViewedImgsArr3.goodsId)">
        <div class="name">{{MostViewedImgsArr3.commonName }}</div>
        <img :src="MostViewedImgsArr3.commonImage" alt="image" class="goods-img">
        <div class="price">
          <div>{{ curSymbol + MostViewedImgsArr3.commonPrice }}</div>
          <img :src="shoppingBgImg" alt="image" @click="goshopping">
        </div>
      </div>
    </div>
    <div class="bot-space-line"></div>
  </div>
</template>

<script>
import { globalVari } from "@/utils/variable";
import { getIndex, listFilterByGoodsIds } from "@/api/index";
import { getTrans } from "@/api/set.js";
import shoppingBgImg from "@/assets/images/shoppingBg.png";
import product08Img from "@/assets/images/product08.png";
import product09Img from "@/assets/images/product09.png";
import product10Img from "@/assets/images/product10.png";
export default {
  name: "Main",
  data() {
    return {
      discountPercent: 0,
      bannerImgList: [], // banner数组
      proList: [], // 商品数组
      shopId: globalVari.shopId, // 店铺id固定
      pIdList: [], // 商品id数组
      newProList: [], // 新品数组
      mostProList: [], // most view product arr
      dPIds: [], // Dream Product ids
      nGIds: [], // New Collection ids
      vGIds: [], // Most Viewed ids
      shoppingBgImg,
      product08Img,
      product09Img,
      product10Img,
      activeMenuIndex: 0, // 当前激活菜单的索引
      menuLists: [
        {
          id: 1,
          name: "Home"
        },
        {
          id: 2,
          name: "Shop"
        },
        {
          id: 3,
          name: "Specialty Pavilion"
        },
        {
          id: 4,
          name: "Discount"
        }
      ],
      merchantLists: [],
      MostViewedImgsArr1: [
        {
          src: product08Img,
          name: "Rotating Lounge Chair",
          price: "39.00",
          iconImg: shoppingBgImg
        }
      ],
      MostViewedImgsArr2: [
        {
          src: product09Img,
          name: "Rotating Lounge Chair",
          price: "39.00",
          iconImg: shoppingBgImg
        }
      ],
      MostViewedImgsArr3: [
        {
          src: product10Img,
          name: "Rotating Lounge Chair",
          price: "39.00",
          iconImg: shoppingBgImg
        }
      ],
      curSymbol: ""
    };
  },

  created() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
    this.fetchData();
  },

  activated() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
  },

  methods: {
    // 用户获取商铺首页
    fetchData() {
      let data = {
        shopId: this.shopId
      };

      getIndex(data).then(res => {
        if (res.code == 1) {
          if (res.data) {
            this.dPIds = res.data.discountGoodsIdList;
            this.nGIds = res.data.newGoodsIdList;
            this.vGIds = res.data.viewGoodsIdList;
            let arr = this.dPIds.concat(this.nGIds, this.vGIds);
            this.pIdList = [...new Set(arr)];
            this.getProductsDetails(this.pIdList);
            this.bannerImgList = [];
            this.discountPercent = res.data.discountPercent;
            let obj = {};
            res.data.bannerUrlList.forEach(element => {
              obj = {
                url: element
              };
              this.bannerImgList.push(obj);
            });

            this.merchantLists = res.data.brandVOs;
          } else {
            this.$message({
              message: getTrans("No data"),
              type: "error",
              customClass: "auto-mes-box"
            });
          }
        } else {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box"
          });
        }
      });
    },

    // 获取首页商品详细信息
    getProductsDetails(params) {
      let data = {
        goodsIds: params,
        shopId: this.shopId
      };
      listFilterByGoodsIds(data).then(res => {
        if (res.code == 1 && res.data) {
          this.proList = res.data.filter(item =>
            this.dPIds.includes(item.goodsId)
          );
          this.newProList = res.data.filter(item =>
            this.nGIds.includes(item.goodsId)
          );
          this.mostProList = res.data.filter(item =>
            this.vGIds.includes(item.goodsId)
          );
          this.MostViewedImgsArr1 = this.mostProList[0];
          this.MostViewedImgsArr2 = this.mostProList[1];
          this.MostViewedImgsArr3 = this.mostProList[2];
        } else {
          this.$message({
            message: res.message,
            type: "error"
          });
        }
      });
    },

    // 跳转商品详情
    goProductsDesc(id) {
      this.$router.push({
        path: "/productsDesc",
        query: {
          goodsId: id
        }
      });
    },

    // 立即购买
    shopnow() {},

    skipBrand(id, title) {
      this.$router.push({
        path: "/BrandDescribe",
        query: { brandId: id, branTitle: title }
      });
    },

    goshopping() {},

    setActiveMenu(index) {
      this.activeMenuIndex = index;
    }
  }
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-carousel__indicators--vertical {
//   top: 50%;
//   transform: translateY(-50%);
// }
.main {
  flex: 1;
  background-color: #fff;
}

.top {
  // min-width: 60%;
  height: 80px;
  width: 80%;
  margin: 0 auto;
  background: #fff;
  .logo {
    float: left;
    text-align: center;
    vertical-align: bottom;
    padding-right: 30px;
    // line-height: 80px;
    padding-top: 25px;

    img {
      height: 30px;
      width: 100px;
    }
  }
  .classification {
    float: left;
    text-align: center;
    vertical-align: bottom;
    padding-right: 10px;
    padding-top: 32px;
    img {
      height: 16px;
      width: 20px;
    }
  }
  .menuLists {
    display: flex;
    justify-content: space-around;
    line-height: 80px;
    li {
      list-style: none;
      float: left;
      cursor: pointer;
      color: #555;
    }
    .active {
      color: #262626;
      font-weight: 550;
    }
  }
  .icons {
    line-height: 80px;
    float: right;
    li {
      margin: 0 20px;
      float: left;
    }
    li:last-child {
      margin-right: 0;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.banner {
  width: 1200px;
  margin: 0 auto;

  li {
    display: table-caption;
  }

  .el-carousel--horizontal {
    overflow-y: hidden;
  }

  .el-carousel__indicators--vertical {
    right: 3.4%;
    top: 50%;
    transform: translateY(-10%);
  }

  .el-carousel__item h3 {
    color: #fff;
    font-size: 80px;
    font-weight: bold;
    margin: 0;
    text-align: left;
    margin-top: 190px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .medium {
    padding-left: 360px;
  }

  .shopnow {
    cursor: pointer;
    // margin-top: 70px;
    margin-top: 450px;
    padding: 20px 18px;
    border-radius: 80px;
    border: 1px solid #fff;
    width: 200px;
    text-align: center;
    font-weight: normal;
    font-size: 32px;
    line-height: 20px;
    img {
      padding-left: 5px;
    }
  }

  .el-carousel__item:nth-child(2n) {
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.sec-section {
  width: 1200px;
  height: 255px;
  margin: 0 auto;
  position: relative;
  background: url("@/assets/images/sec-section-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .disCountVal {
    position: absolute;
    top: 51%;
    right: 19%;
    color: #fff;
    font-size: 45px;
    transform: rotate(-60deg) translate(-50%, -50%);
  }
}

.third-section {
  width: 1200px;
  height: 420px;
  margin: 0 auto;
  position: relative;
  background: url("@/assets/images/third-section-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .el-carousel--horizontal {
    overflow-y: hidden;
  }

  .ths-products {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 105px;

    .t-item-img {
      width: 60%;
      margin: 50px 20px 0px;
    }

    .ths-products-img:hover {
      box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.35);
    }

    .ths-products-img:first-child {
      margin-left: 0 !important;
    }

    .ths-products-img {
      background: #fff;
      border-radius: 40px;
      width: 228px;
      height: 252px;
      margin-right: 40px;
      cursor: pointer;
      position: relative;

      .ths-products-name {
        position: absolute;
        top: 20px;
        left: 22px;
        width: 100%;
        font-size: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        font-weight: 550;
      }

      .ths-products-price {
        position: absolute;
        bottom: 18px;
        left: 0;
        width: 100%;
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tpp-val {
          padding-left: 20px;
        }

        .shop-img-icon {
          padding-right: 20px;
        }

        img {
          width: 22px;
          cursor: pointer;
        }
      }
    }
  }
}

.forth-section {
  width: 1200px;
  height: 463px;
  margin: 0 auto;
  position: relative;
  background: url("@/assets/images/forth-section-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  // .el-carousel--horizontal {
  //   overflow-x: hidden !important;
  //   overflow-y: hidden !important;
  // }

  .forth-space {
    height: 167px;
  }

  .el-carousel--horizontal {
    overflow-x: visible !important;
  }

  .el-carousel__indicators--horizontal {
    display: none !important;
  }

  .el-carousel__item {
    overflow: visible;
  }

  .for-products {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;

    .f-item-img {
      width: 90%;
      position: absolute;
      top: 15%;
      left: 5%;
    }

    .for-products-img:first-child {
      margin-left: 0 !important;
    }

    .for-products-img:nth-child(2n + 2) {
      margin-bottom: 100px;
      // height: 330px;
    }

    .for-products-img:last-child {
      margin-right: 0;
    }

    .for-products-img:hover {
      background: #f0f0f0;
      box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.35);
    }

    .for-products-img {
      cursor: pointer;
      background: #f3f3f5;
      border-radius: 40px;
      margin-right: 32px;
      position: relative;
      width: 204px;
      height: 280px;

      .for-products-desc {
        text-align: left;
        position: absolute;
        top: 20px;
        left: 22px;
        width: 219px;
        font-size: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // text-align: center;
        font-weight: 550;
      }

      .for-products-price {
        position: absolute;
        bottom: 19px;
        left: 0;
        color: #865336;
        font-size: 13px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .fpp-val {
          padding-left: 20px;
        }

        .shop-img-icon {
          padding-right: 20px;
        }

        img {
          width: 22px;
          cursor: pointer;
        }
      }
    }
  }
}

.fif-section {
  width: 1200px;
  height: 254px;
  margin: 0 auto;
  position: relative;
  background: url("@/assets/images/fifth-section-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .merchant {
    position: absolute;
    left: 0;
    bottom: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    justify-items: center;

    div {
      width: 125px;
      cursor: pointer;

      img {
        width: 100%;
      }
    }
  }
}

.six-section {
  width: 1200px;
  height: 675px;
  margin: 0 auto;
  position: relative;
  background: url("@/assets/images/sixth-section-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .mostVieweproducts {
    position: absolute;
    bottom: 0;
    left: 338px;
    width: 188px;
    height: 288px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 30px;
    background: rgba(204, 204, 204, 1);

    .name {
      color: rgba(0, 0, 0, 0.85);
      font-size: 24px;
      padding-top: 20px;
      padding-left: 20px;
      font-size: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      font-weight: 550;
    }

    .goods-img {
      width: 80%;
      margin: 0 auto;
      margin-top: 24px;
    }

    .price {
      color: rgba(127, 73, 42, 1);
      // font-size: 20px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 23px;

      img {
        width: 23px;
        cursor: pointer;
      }
    }
  }

  .mostVieweproductsTwo {
    position: absolute;
    bottom: 0;
    right: 450px;
    width: 188px;
    height: 438px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 30px;
    background: rgba(185, 132, 101, 1);

    .name {
      color: rgba(0, 0, 0, 0.85);
      // font-size: 24px;
      padding-top: 20px;
      padding-left: 20px;
      font-size: 15px;
      font-weight: 550;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }

    .goods-img {
      width: 80%;
      margin: 0 auto;
      margin-top: 85px;
    }

    .price {
      color: rgba(127, 73, 42, 1);
      // font-size: 20px;
      margin-top: 57px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 23px;

      img {
        width: 23px;
        cursor: pointer;
      }
    }
  }

  .mostVieweproductsThree {
    position: absolute;
    bottom: 129px;
    right: 225px;
    width: 188px;
    height: 438px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 30px;
    background: rgba(185, 132, 101, 1);

    .name {
      color: rgba(0, 0, 0, 0.85);
      font-size: 24px;
      padding-top: 20px;
      padding-left: 20px;
      font-size: 15px;
      font-weight: 550;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }

    .goods-img {
      width: 80%;
      margin: 0 auto;
      margin-top: 85px;
    }

    .price {
      color: rgba(127, 73, 42, 1);
      // font-size: 20px;
      margin-top: 82px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 23px;

      img {
        width: 23px;
        cursor: pointer;
      }
    }
  }

  .mostVieweproducts:hover,
  .mostVieweproductsTwo:hover,
  .mostVieweproductsThree:hover {
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.35);
  }
}

.bot-space-line {
  // height: 60px;
}

.copyRight {
  position: relative;
  height: calc(100vh - 860px);
  margin-top: 500px;
  background: #1c0a00;
  height: 60px;

  .copyRightContent {
    display: flex;
    justify-content: space-around;
    justify-items: center;
    color: #c3bebc;
    font-size: 16px;
    line-height: 60px;
  }
}
</style>