<template>
    <div class="BrowseBrand">
      <Header/>
      <div class="title">{{'The order has been successfully paid.' | formatTrans}}</div>
      <div class="view-btn" @click="skipOrderHis">
        <span>View orders</span>
      </div>
      <Footer/>
    </div>
  </template>
  <script>
  
  import Header from "@/views/layout/Header.vue";
  import Footer from "@/views/layout/Footer.vue";

  export default {
    name: "PaymentSuc",
    components: {
      Header,
      Footer
    },
    data() {
      return {};
    },

    created() {
      
    },
    methods: {

      skipOrderHis() {
        this.$router.push("/OrderHistory")
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  .BrowseBrand {
    height: 100%;

    .title {
      position: absolute;
      width: 100%;
      top: 29%;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      font-size: 40px;
      text-align: center;
      font-family: ArialMT;
    }

    .view-btn {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translateX(-50%);
        width: 220px;
        height: 48px;
        text-align: center;
        line-height: 48px;
        border-radius: 29px;
        background-color: rgba(185, 132, 101, 1);
        color: #fff;
        font-size: 16px;
        cursor: pointer;
    }

  }
  </style>
  
  