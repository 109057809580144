<template>
  <div class="resetPassword">
    <Header/>
    <div class="chp-top-space-line"></div>
    <div class="chp-wrap">
      <div class="titles">
        <div class="titleBig">{{'修改密码' | formatTrans}}</div>
      </div>
      <div class="line">
        <img src="@/assets/images/login/login.png">
      </div>
      <div class="box">
        <div class="box-in">
          <el-form :model="loginChpForm" :rules="rules" ref="loginChpForm" class="loginChpForm">
            <el-form-item prop="curPassword">
              {{'当前密码' | formatTrans}} *
              <el-input
                type="password"
                v-model="loginChpForm.curPassword"
                clearable
                placeholder
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item prop="newPassword">
              {{'新密码' | formatTrans}} *
              <el-input
                type="password"
                v-model="loginChpForm.newPassword"
                clearable
                placeholder
                show-password
              ></el-input>
              <div class="passwordTips">{{ '请输入8个或更多字符以及至少一个数字。' | formatTrans}}</div>
            </el-form-item>
            <el-form-item prop="cNewPassword">
              {{'确认新密码' | formatTrans}} *
              <el-input
                type="password"
                v-model="loginChpForm.cNewPassword"
                clearable
                placeholder
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item class="footerBtn">
              <el-button round @click="cancelSubmit" class="cancelbutton">Cancel</el-button>
              <el-button
                type="primary"
                round
                @click="submitForm"
                class="button"
              >Confirm</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="chp-bot-space-line"></div>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import { loginResetPas, logout } from "@/api/index";
import { getTrans } from "@/api/set.js";
import CryptoJS from "crypto-js";

export default {
  name: "ChangePassword",
  components: {
    Header,
    Footer
  },

  data() {
    var validatePassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(getTrans("请输入密码")));
      }

      if (value.trim() === this.loginChpForm.curPassword.trim()) {
        return callback(
          new Error(
            getTrans(
              "原密码和新密码不能相同。"
            )
          )
        );
      }

      if (!/^(?=.*\d).{8,}$/.test(value)) {
        callback(
          new Error(getTrans("密码至少包含8个字符且必须包含一个数字"))
        );
      } else {
        callback();
      }
    };

    var confirmPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(getTrans("请再次输入你的密码")));
      } else if (value !== this.loginChpForm.newPassword) {
        callback(
          new Error(
            getTrans("确认密码与密码不一致")
          )
        );
      } else {
        callback();
      }
    };

    return {
      loginChpForm: {
        curPassword: "",
        newPassword: "",
        cNewPassword: ""
      },
      rules: {
        curPassword: [
          {
            required: true,
            message: "Current Password is required",
            trigger: "blur"
          }
        ],
        newPassword: [
          {
            required: true,
            trigger: "blur",
            validator: validatePassword
          }
        ],
        cNewPassword: [
          {
            required: true,
            trigger: "blur",
            validator: confirmPassword
          }
        ]
      }
    };
  },

  methods: {
    submitForm() {
      let data = {
        oldPassword: CryptoJS.MD5(this.loginChpForm.curPassword).toString(),
        newPassword: CryptoJS.MD5(this.loginChpForm.newPassword).toString(),
        token: sessionStorage.getItem("token")
      };

      this.$refs["loginChpForm"].validate(valid => {
        if (valid) {
          loginResetPas(data).then(response => {
            if (response.code == 1) {
              this.$message({
                message: getTrans("修改成功"),
                type: "success",
                duration: 1000,
                customClass: "auto-mes-box"
              });

              this.$router.push("/MyAccount");
            } else if (response.code == 10016) {
              this.$message({
                message: getTrans(res.message),
                type: "error",
                customClass: "auto-mes-box",
                duration: 3000
              });

              logout({ token: sessionStorage.getItem("token") }).then(res => {
                if (res.code == 1) {
                  sessionStorage.removeItem("token");
                  localStorage.removeItem("emailId");
                  this.$router.replace("/Login");
                }
              });
            } else {
              this.$message({
                message: getTrans(response.message),
                type: "error",
                duration: 3000,
                customClass: "auto-mes-box"
              });
            }
          });
        }
      });
    },

    cancelSubmit() {
      this.$router.push("/MyAccount");
    }
  }
};
</script>
<style lang="scss" scoped>
.resetPassword {
  .chp-top-space-line {
    height: 80px;
  }

  .chp-bot-space-line {
    height: 100px;
  }

  .chp-wrap {
    width: 1200px;
    margin: 0 auto;
    margin-top: 48px;

    .titles {
      text-align: center;

      .titleBig {
        font-size: 40px;
        color: #333;
        font-weight: 900;
      }

      .titleSmall {
        font-size: 14px;
        color: #666;
        margin: 10px 0;
      }
    }

    .line {
      width: 877px;
      margin: 0 auto;
      margin-top: 44px;

      img {
        width: 100%;
      }
    }

    .box {
      margin-top: 59px;
      padding: 50px 0;
      font-size: 16px !important;
      background: #f3f3f5;

      ::v-deep .el-form-item__content,
      ::v-deep .el-checkbox__label {
        font-size: 18px !important;
      }

      .box-in {
        width: 426px;
        margin: 0 auto;
      }

      .footerBtn {
        display: flex;
        justify-content: space-between;

        .cancelbutton {
          width: 205px;
          color: #2c3e50;
          background-color: #fff;
          border-color: #fff;
          font-size: 18px !important;
          margin-top: 20px;
        }
        .button {
          width: 205px;
          color: #fff;
          background-color: #b98465;
          border-color: #b98465;
          font-size: 18px !important;
          margin-top: 20px;
        }
      }

      .passwordTips {
        margin-top: 5px;
        color: rgba(0, 0, 0, 0.55);
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
</style>

