<template>
  <div class="AddressBook">
    <Header/>
    <div class="adr-a-top-space-line"></div>
    <div class="addrb-wrap">
      <div class="titles">
        <div class="titleBig">{{'地址簿' | formatTrans}}</div>
      </div>
      <div class="line">
        <img src="@/assets/images/login/login.png">
      </div>
      <div class="box">
        <p v-if="this.$route.query.flag == '0'">{{'新增地址' | formatTrans}}</p>
        <p v-if="this.$route.params.flag == '1'">{{'修改地址' | formatTrans}}</p>
        <div class="box-in">
          <el-form :model="addressForm" :rules="rules" ref="addressForm" class="addressForm">
            <el-form-item prop="firstName">
              {{'名' | formatTrans}} *
              <el-input type="text" v-model="addressForm.firstName" clearable placeholder></el-input>
            </el-form-item>
            <el-form-item prop="lastName">
              {{'姓' | formatTrans}} *
              <el-input type="text" v-model="addressForm.lastName" clearable placeholder></el-input>
            </el-form-item>
            <el-form-item prop="Region">
              <div style="display: flex; flex-direction: column">
                <label>{{'国家/地区' | formatTrans}} *</label>
                <el-select
                  filterable
                  v-model="addressForm.country"
                  placeholder="please check country"
                  @change="countryChange"
                >
                  <el-option
                    v-for="dict in country_list"
                    :key="dict.value"
                    :label="dict.name_en"
                    :value="dict.name_en"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item prop="userAddressArea">
              {{'州或省/市/区' | formatTrans}}
              <el-input type="text" v-model="addressForm.userAddressArea" clearable placeholder></el-input>
            </el-form-item>
            <el-form-item prop="userAddressAddress">
              {{'详细地址' | formatTrans}} *
              <el-input type="text" v-model="addressForm.userAddressAddress" clearable placeholder></el-input>
            </el-form-item>
            <el-form-item prop="zipCode">
              {{'邮政编码' | formatTrans}} *
              <el-input type="text" v-model="addressForm.zipCode" clearable placeholder></el-input>
            </el-form-item>
            <el-form-item prop="userAddressPhone">
              {{'手机号码' | formatTrans}} *
              <el-input type="text" v-model="addressForm.userAddressPhone" clearable placeholder></el-input>
            </el-form-item>
            <el-form-item prop="addressDefault">
              <el-checkbox
                v-model="addressDefault"
                @change="defChange"
                class="remember"
              >{{'设置为默认地址'| formatTrans}}</el-checkbox>
            </el-form-item>
            <el-form-item class="footerBtn">
              <el-button round @click="CancelFn()" class="cancelbutton">Cancel</el-button>
              <el-button
                type="primary"
                round
                @click="submitForm()"
                class="button"
              >Save</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="adr-a-bot-space-line"></div>
    <Footer/>
  </div>
</template>
<script>
import { addAddress, updateAddress, logout } from "@/api/index";
import { getTrans } from "@/api/set.js";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import country from "@/utils/country.json";
const originList = country;
export default {
  name: "Home",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      country_list: originList,
      hasAddress: false, //是否有地址
      //密码小眼睛切换状态
      flag: false,
      flagType: "password",
      addressDefault: false,
      addressForm: {},
      forgotdialogVisible: false,
      forgotdialogVisibleSend: false,
      forgotForm: {},
      rules: {
        EmailAddress: [
          {
            required: true,
            message: "EmailAddress is required",
            trigger: "blur"
          }
        ]
      }
    };
  },

  created() {
    if (this.$route.params.flag == 1 && this.$route.params.dataS) {
      this.addressForm = this.$route.params.dataS;
      if (this.$route.params.dataS.userAddressDefault == 1) {
        this.addressDefault = true;
      } else {
        this.addressDefault = false;
      }
    }
  },

  methods: {
    // 国家改变
    countryChange(value) {
      this.addressForm.country = value;
    },
    // 默认地址变化
    defChange(event) {
      if (event == true) {
        this.addressForm.userAddressDefault = 1;
      } else {
        this.addressForm.userAddressDefault = 0;
      }
    },
    //小眼睛切换状态
    getFlag() {
      this.flag = !this.flag;
      this.flagType = this.flag ? "text" : "password"; //text为显示密码；password为隐藏密码
    },
    // 取消
    CancelFn() {
      if (this.$router.currentRoute.path !== "/AddressBook") {
        this.$router.replace("/AddressBook");
      }
    },
    // 保存地址
    submitForm() {
      if (this.$route.params.flag == 1 && this.$route.params.dataS) {
        let data = {
          userAddressId: this.$route.params.dataS.userAddressId,
          token: sessionStorage.getItem("token"),
          firstName: this.addressForm.firstName,
          lastName: this.addressForm.lastName,
          country: this.addressForm.country,
          userAddressDefault: this.$route.params.dataS.userAddressDefault,
          userAddressPhone: this.addressForm.userAddressPhone,
          userAddressAddress: this.addressForm.userAddressAddress,
          zipCode: this.addressForm.zipCode,
          userAddressArea: this.addressForm.userAddressArea

          // userAddressAreaId: this.addressForm.userAddressArea,
          // userAddressAreaName: this.addressForm.userAddressArea,
          // userAddressCityId: this.addressForm.userAddressArea,
          // userAddressCityName: this.addressForm.userAddressArea,
          // userAddressProvinceId: this.addressForm.userAddressArea,
          // userAddressProvinceName: this.addressForm.userAddressArea,
        };
        this.update(data);
      } else {
        let data = {
          token: sessionStorage.getItem("token"),
          firstName: this.addressForm.firstName,
          lastName: this.addressForm.lastName,
          country: this.addressForm.country,
          userAddressDefault: this.addressForm.userAddressDefault,
          userAddressPhone: this.addressForm.userAddressPhone,
          userAddressAddress: this.addressForm.userAddressAddress,
          zipCode: this.addressForm.zipCode,
          userAddressArea: this.addressForm.userAddressArea
        };
        this.add(data);
      }
    },
    // 添加操作
    add(data) {
      addAddress(data).then(res => {
        if (res.code == 1) {
          this.$message({
            message: getTrans("添加地址成功"),
            type: "success",
            customClass: "auto-mes-box",
            duration: 3000
          });

          this.$router.push("/AddressBook");
        } else if (res.code == 10016) {
          this.hasAddress = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.hasAddress = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });
        }
      });
    },
    // 更新操作
    update(data) {
      updateAddress(data).then(res => {
        if (res.code == 1) {
          this.$router.push("/AddressBook");
        } else {
          this.hasAddress = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.AddressBook {
  .adr-a-top-space-line {
    height: 80px;
  }

  .adr-a-bot-space-line {
    height: 100px;
  }

  .addrb-wrap {
    width: 1200px;
    margin: 0 auto;
    margin-top: 48px;

    .titles {
      text-align: center;

      .titleBig {
        font-size: 40px;
        color: #333;
        font-weight: 900;
      }

      .titleSmall {
        font-size: 14px;
        color: #666;
        margin: 10px 0;
      }
    }

    .line {
      width: 877px;
      margin: 0 auto;
      margin-top: 44px;

      img {
        width: 100%;
      }
    }

    .box {
      margin-top: 59px;
      padding: 50px 0;
      font-size: 16px !important;
      background: #f3f3f5;

      ::v-deep .el-form-item__content,
      ::v-deep .el-checkbox__label {
        font-size: 18px !important;
      }

      p {
        font-size: 24px;
        text-align: center;
        color: #242424;
        padding: 30px;
      }

      .box-in {
        width: 426px;
        margin: 0 auto;
      }

      .footerBtn {
        display: flex;
        justify-content: space-between;

        .cancelbutton {
          width: 205px;
          color: #2c3e50;
          background-color: #fff;
          border-color: #fff;
          font-size: 18px !important;
          margin-top: 20px;
        }

        .button {
          width: 205px;
          color: #fff;
          background-color: #b98465;
          border-color: #b98465;
          font-size: 18px !important;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>

