<template>
  <div class="home">
    <Header/>
    <div class="top-space-line">&nbsp;</div>
    <Main/>
    <div class="footer">
      <!-- 在这里放置底部的内容 -->
      <div class="box">
        <div class="copyRight">
          <div
            class="copyRightContent"
          >{{'版权所有 © 2021-2024 保留所有权利.技术支持：江苏鹤都云人工智能科技有限公司' | formatTrans}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./views/layout/Header.vue";
import Main from "./views/layout/Main.vue";
import Footer from "./views/layout/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    Main,
    Footer
  }
};
</script>

<style lang="scss" scoped>
.footer {
  background: #1c0a00;
  width: 100%;
  .box {
    width: 1200px;
    margin: 0 auto;
    // position: fixed;
    z-index: 99999;
    bottom: 0;
    height: 60px;
    background: #1c0a00;
    .copyRight {
      .copyRightContent {
        text-align: center;
        color: #c3bebc;
        font-size: 16px;
        line-height: 60px;
      }
    }
  }
}
.home {
  height: 100vh;
}

.top-space-line {
  width: 100%;
  height: 80px;
}

.content {
  flex: 1;
  display: flex;
  overflow: hidden;
}
</style>